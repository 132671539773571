import Vue from 'vue'
import VueApollo from 'vue-apollo'

import { HttpLink } from 'apollo-link-http'
import { ApolloLink, Observable } from 'apollo-link'
import { RetryLink } from 'apollo-link-retry'
import { onError } from 'apollo-link-error'
import { createApolloClient, restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client'

// add module import and define apolloClient type
import { ApolloClient } from 'apollo-client'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { Store } from 'vuex'
import router from '@/router'
import { State } from '@/store/state'
import store from '@/store'
import { sendError } from '@/utils/notificationError'
import { getErrorMessage } from '@/utils/translations'

// Install the vue plugin
Vue.use(VueApollo)

// Name of the localStorage item
export const AUTH_TOKEN = 'apollo-token'
export const INVALID_JWT = 'invalid-jwt'
export const USER = 'unexpected'
export const RUT_ERROR = '412'
export const RUT_API = '503'
export const INVALID_RUT = 'BAD_USER_INPUT'

const extractOperationQueryName = operationBody => {
  const match = operationBody.match(/\b(query|mutation)\b/)
  return match ? match[1] : null
}

const extractOperationName = operationBody => {
  const match = operationBody.match(/\b(?:query|mutation)\s*(\w+)/)
  return match ? match[1] : 'Unnamed Operation'
}

const errorHandlerLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    const context = operation.getContext()
    if (response?.errors?.length && !context.isTryingRoles) {
      for (const { message, extensions } of response.errors) {
        if (extensions) {
          const filters = JSON.stringify(operation.variables)
          const operationBody = operation.query.loc.source.body
          const operationName = extractOperationName(operationBody)
          const currentRoute = router.currentRoute.fullPath
          const requestRoot = window.location.origin
          const fullUrl = `${requestRoot}${currentRoute}`
          const error = {
            app_name: `GENIO`,
            view: `${fullUrl}`,
            record_id: `Datos usuario ${JSON.stringify(store.getters['user/user'])}`,
            context: `Message: ${message},  Extension Code: ${extensions.code}
                Query: ${operationName} Variables: ${filters}`,
          }
          switch (extensions.code) {
            case INVALID_JWT:
              throw new Error(INVALID_JWT)
            case RUT_ERROR:
              throw new Error(RUT_ERROR)
            case RUT_API:
              throw new Error(RUT_ERROR)
            case INVALID_RUT:
              throw new Error('Invalid RUT')
            case USER:
              const msg = extensions?.internal?.error?.message || 'Contacta al soporte técnico'
              const errorMessage = getErrorMessage('ERROR_CUSTOMER_BUSINESS_OPEN', msg) || msg
              if (errorMessage === 'Contacta al soporte técnico') {
                sendError(error)
              }

              window.dispatchEvent(
                new CustomEvent('notification-message', {
                  detail: {
                    message: errorMessage,
                    type: 'error',
                  },
                }))
              break
            default:
              sendError(error)
              window.dispatchEvent(
                new CustomEvent('notification-message', {
                  detail: {
                    message: 'Contacta al soporte técnico',
                    type: 'error',
                  },
                }))
              // eslint-disable-next-line no-console
              console.log(`[GraphQL error]: Message: ${message},  Extension Code: ${extensions.code}`)
          }
        }
      }
    }
    return response
  })
})

function getOrderedRoles (currentPath, roles) {
  if (!currentPath.includes('person') && !currentPath.includes('schedule')) {
    return null
  }

  const priorityOrder = ['staff', 'supervisor', 'validator']
  const rolesArray = roles?.map(r => r.slug) || []

  rolesArray.sort((a, b) => {
    const aIndex = priorityOrder.indexOf(a)
    const bIndex = priorityOrder.indexOf(b)

    if (aIndex === -1 && bIndex === -1) {
      return 0 // Both are not in the priority list, keep their order
    }
    if (aIndex === -1) {
      return 1 // a is not in the priority list, so it goes after b
    }
    if (bIndex === -1) {
      return -1 // b is not in the priority list, so a goes before b
    }
    return aIndex - bIndex // Both are in the priority list, sort by their order in priorityOrder
  })

  return rolesArray
}

export async function ensureRolesAreLoaded (store, isMutation) {
  const roles = store.getters['user/roles']
  const currentPath = router.currentRoute.path

  if (!isMutation) {
    return roles.map(r => r.slug)
  }

  if (currentPath.includes('_')) {
    const paths = currentPath.split('/')
    const role = paths.find(p => p.includes('_'))?.split('_')[0]

    return [role.toLowerCase()]
  }

  if (currentPath.includes('person') || currentPath.includes('schedule')) {
    return getOrderedRoles(currentPath, roles) || null
  }

  if (currentPath.includes('home')) {
    return roles?.filter(r => r.slug.toLowerCase() === 'supervisor')?.map(r => r.slug) || null
  }

  return roles?.length ? roles.map(r => r.slug) : null
}

export async function ensureAuthReady (store, isMutation = false) {
  const token = localStorage.getItem(AUTH_TOKEN)
  const admin = store.getters['user/defaultRole'] === 'admin'
  let roles = admin ? null : []

  if (!admin) {
    roles = await ensureRolesAreLoaded(store, isMutation)
  }

  return { token, roles }
}

const auth = new ApolloLink((operation, forward) => {
  const tryRequest = (observer, { token, roles, index = 0 }, accumulatedErrors = []) => {
    const headers = {
      authorization: `Bearer ${token}`,
    }

    if (roles[index]) {
      headers['x-hasura-role'] = roles[index]
    }

    operation.setContext({ headers, isTryingRoles: roles.length > 1 && index < roles.length - 1 })

    forward(operation).subscribe({
      next: response => {
        // Verificar si la respuesta es exitosa (contiene datos)
        if (response.hasOwnProperty('data')) {
          observer.next(response)
          observer.complete() // Completa el observable inmediatamente cuando hay éxito
        } else if (index < roles.length - 1) {
          // Acumular errores si no hay datos y hay más roles por probar
          const newErrors = [...accumulatedErrors, ...response.errors]
          tryRequest(observer, { token, roles, index: index + 1 }, newErrors)
        } else {
          // Si todos los roles han sido probados sin éxito, notificar los errores
          const errorMessage = accumulatedErrors.map(e => e.message).join('; ')
          observer.error(new Error(`No se pudo completar la operación con ninguno de los roles disponibles. Errores: ${errorMessage}`))
        }
      },
      error: err => {
        // Manejar errores de red o de consulta
        observer.error(err)
      },
      complete: () => {
        if (index >= roles.length - 1) {
          observer.complete()
        }
      },
    })
  }

  const operationBody = operation.query.loc.source.body
  const operationName = extractOperationQueryName(operationBody)
  const isMutation = operationName === 'mutation'

  return new Observable(observer => {
    ensureAuthReady(store, isMutation).then(authReady => {
      const { token, roles } = authReady
      const initialRoles = roles && roles.length > 0 ? roles : [null] // Asegurar que haya al menos un intento

      tryRequest(observer, { token, roles: initialRoles })
    }).catch(error => {
      console.error('Error in auth link:', error)
      observer.error(error)
    })
  })
})

export type VueApolloClient = ApolloClient<InMemoryCache> & {
  wsClient: SubscriptionClient
};

const errorLink = onError(({ graphQLErrors, networkError, forward, operation }) => {
  if (networkError && networkError.message.toLowerCase().includes('invalid-jwt')) {
    return new Observable(observer => {
      store.dispatch('user/refreshToken').then(() => {
        operation.setContext(({ headers = {} }) => ({
          headers: {
            ...headers,
            authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
          },
        }))

        forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        })
      }).catch(error => {
        observer.error(error)
        const filters = JSON.stringify(operation.variables)
        const operationBody = operation.query.loc.source.body
        const operationName = extractOperationName(operationBody)
        const currentRoute = router.currentRoute.fullPath
        const requestRoot = window.location.origin
        const fullUrl = `${requestRoot}${currentRoute}`
        const newError = {
          app_name: `GENIO`,
          view: `${fullUrl}`,
          record_id: `Datos usuario ${JSON.stringify(store.getters['user/user'])}`,
          context: `${JSON.stringify(error.message)} Query: ${operationName} Variables: ${filters}`,
        }
        sendError(newError)
        window.dispatchEvent(
          new CustomEvent('notification-message', {
            detail: {
              message: `Contacta soporte tecnico mensaje: ${error.message}`,
              type: 'error',
            },
          })
        )
      })
    })
  }

  const context = operation.getContext()

  if (graphQLErrors && !context.isTryingRoles) {
    for (const err of graphQLErrors) {
      console.error(
        `[GraphQL error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`
      )
    }
  } else {
    return forward(operation)
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`)
    window.dispatchEvent(
      new CustomEvent('notification-message', {
        detail: {
          message: `Error de red, verifica tu conexión: ${networkError.message}`,
          type: 'error',
        },
      })
    )
  }
})

const retryLink = new RetryLink({
  attempts: (count, operation, error) => {
    const isNetworkError = error.statusCode >= 500
    const isInvalidJWT = error.message === INVALID_JWT && count < 3

    if (isInvalidJWT) {
      return true
    }

    return isNetworkError && count < 3
  },
  delay: count => {
    return count * 300 // Incrementa el tiempo de espera entre reintentos
  },
})

const httpLink = new HttpLink({
  uri: process.env.VUE_APP_GRAPHQL_HTTP || 'http://localhost:4000/graphql',
  fetch,
})

// Http endpoint
const httpEndpoint =
  process.env.VUE_APP_GRAPHQL_HTTP || 'http://localhost:4000/graphql'
// Files URL root
export const filesRoot =
  process.env.VUE_APP_FILES_ROOT ||
  httpEndpoint.substr(0, httpEndpoint.indexOf('/graphql'))

Vue.prototype.$filesRoot = filesRoot

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:4000/graphql',
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  // link: myLink
  preAuthLinks: [auth, retryLink, errorLink, errorHandlerLink, httpLink],
  // Override default cache
  // cache: myCache

  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
}

// Call this in the Vue app file
export function createProvider (options = {}): VueApollo {
  // Create apollo client
  const { apolloClient, wsClient } = createApolloClient({
    ...defaultOptions,
    ...options,
  })
  // @ts-ignore
  apolloClient.wsClient = wsClient

  // Create vue apollo provider
  return new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: 'cache-and-network',
      },
    },
    errorHandler (error) {
      console.log(
        '%cError',
        'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
        error.message
      )
    },
  })
}

// Manually call this when user log in
export async function onLogin (
  apolloClient: VueApolloClient,
  token: string
): Promise<void> {
  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem(AUTH_TOKEN, token)
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(
      '%cError on cache reset (login)',
      'color: orange;',
      // @ts-ignore
      e.message
    )
  }
}

// Manually call this when user log out
export async function onLogout (apolloClient: VueApolloClient): Promise<void> {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(AUTH_TOKEN)
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(
      '%cError on cache reset (logout)',
      'color: orange;',
      // @ts-ignore
      e.message
    )
  }
}

export function initApolloClient (
  store: Store<State>,
  provider: VueApollo
): void {
  store.state.apolloClient = provider.defaultClient as VueApolloClient
}
