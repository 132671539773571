
  import { Component, Watch } from 'vue-property-decorator'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import { plainToInstance } from 'class-transformer'
  import { Appraisal, Inspection } from '@/entities/purchase'
  import { Form } from '@/entities/public/Resource/metadata'
  import { Debounce } from '@/utils/decorators'
  import { deepCopy, fixPrice, isValidNumber, updateNestedObject } from '@/utils/general'
  import { Auto, DealAutoAttribute } from '@/entities/public'
  import LinkedAuto from '@/components/forms/fields/LinkedAuto.vue'
  import { Deal, Lead } from '@/entities/crm'
  import GFiles from '@/components/core/files/GFiles.vue'
  import { FileProcess } from '@/entities/files'
  import { AppraisalView } from '@/components/forms/view/AppraisalView'
  import LinkedPerson from '@/components/forms/fields/LinkedPerson.vue'
  import AutoResume from '@/components/forms/AutoResume.vue'
  import _ from 'lodash'
  import { ClosingReason } from '@/entities/settings'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import TechnicalSpecification from '@/components/forms/appraisal/TechnicalSpecification.vue'

@Component({
  components: {
    GAlert,
    AutoResume,
    LinkedPerson,
    GFiles,
    LinkedAuto,
    BaseForm,
    TechnicalSpecification,
  },
  methods: { fixPrice },
  computed: {},
})
  export default class AppraisalForm extends AppraisalView {
  appraisal: Appraisal = plainToInstance(Appraisal, {});
  lead: Lead = plainToInstance(Lead, {});

  attributes: [DealAutoAttribute] = [plainToInstance(DealAutoAttribute, {})];
  inspection: Inspection = plainToInstance(Inspection, {});
  vehicles: Array<Auto> = [];
  idProcess = null;
  loading = true;

  declare $refs: {
    form: HTMLFormElement;
    fieldAuto: LinkedAuto;
  };

  isUploadingFile = false;
  hasOffer1 = false;
  hasOffer2 = false;
  deal = null;
  appraisalAlert = {
    open: false,
    title: `Modificar la versión del vehículo descontinuará la versión actual para este vehículo y generará una nueva tasación sin oferta que reemplazará a la anterior.`,
    subtitle: `¿Desea continuar?`,
  };

  appraisalResponseAmount = null;
  appraisalResponseAmountConsignment = null;
  alternativeAttributes = null;
  errorMessageConsignment = '';
  expectedPriceRule = [];
  expectedPriceRuleConsignment = [];
  expectedAgreementPriceRule = [];
  expectedAgreementPriceRuleConsignment = [];
  title = '';
  showDetail = false;
  maxPrepaidMaintenances = 0;
  uidRequired = false;
  formData = {
    client: null,
    photos: [],
    cav: [],
    document: [],
    auto: null,
    kms: null,
    owner: null,
    price: null,
    priceConsignment: null,
    offer1: null,
    offer2: null,
    companies: null,
    maintenance: null,
    comment: null,
    commentConsignment: null,
    prepaidMaintenance: null,
    agreementPrice: null,
    agreementPriceConsignment: null,
    linkLegalReport: null,
    link: '',
  };

  metadata = {}
  metadataCollection = {};
  fields = {
    cav: {
      properties: {
        label: 'CAV',
        accept: '',
        fileTypeId: null,
        multiple: false,
        name: '',
        appendOuterIcon: 'mdi-paperclip',
      },
    },
    photos: {
      properties: {
        label: 'Fotos del vehículo',
        accept: '',
        fileTypeId: null,
        name: '',
        multiple: false,
        appendOuterIcon: 'mdi-camera-plus',
      },
    },
    document: {
      properties: {
        label: 'Informe legal',
        accept: '',
        fileTypeId: null,
        multiple: false,
        name: '',
        appendOuterIcon: 'mdi-paperclip',
      },
    },
    linkLegalReport: {
      properties: {},
      rules: this.fieldRequired,
    },
    auto: {
      properties: {
        required: true,
        properties: {
          label: 'Vehículo',
          rules: this.fieldRequired,
          itemText: 'formName',
          itemValue: 'id',
          required: true,
          returnObject: true,
        },
      },
      items: [],
      rules: this.fieldRequired,
    },
    offer1: {
      properties: {
        label: '',
        link: '',
      },
    },
    offer2: {
      properties: {
        label: '',
        link: '',
      },
    },
    owner: {
      properties: {
        label: '',
        itemText: 'description',
        itemValue: 'id',
      },
      items: [],
    },
    maintenance: {
      items: [],
      properties: {
        label: 'Mantenciones realizadas',
        itemText: 'description',
        itemValue: 'id',
        multiple: true,
        returnObject: true,
      },
    },
    prepaidMaintenance: {
      items: [],
      properties: {
        label: 'Mantenciones prepagadas',
        itemText: 'description',
        itemValue: 'id',
        multiple: true,
        returnObject: true,
      },
    },
    button1: 'Chileautos',
    button2: 'Mercadolibre',
  };

  async mounted () {
    await this.setMetadata()
    const { uid, id, model, title, metadataCollection } = this

    if (
      (!isNaN(uid) && (model === 'Appraisal' || model === '')) ||
      !isNaN(id)
    ) {
      const appraisalId = id || uid
      await this.getAppraisalInfo(appraisalId)
    } else if (model === 'Lead' && isValidNumber(uid)) {
      await this.getLeadInfo(uid)
    }
    if (!this.isBreadCrumbPresent(title)) {
      this.setFormCrumbs(
        metadataCollection,
        title,
        Boolean(this.appraisal?.id)
      )
    }
    this.setTheBackup()
  }

  async getLeadInfo (id) {
    this.lead = await this.fetchData({
      query: { name: 'fetch', model: 'Lead', params: { id } },
    })
  }

  async getAppraisalInfo (id) {
    const appraisal = await this.fetchData({
      query: { name: 'fetch', model: 'Appraisal', params: { id } },
      force: true,
    })
    this.deal = appraisal.deal
    this.appraisal = appraisal
    this.formData.link = appraisal?.link
    if (!this.appraisalNotQualify) {
      this.appraisalResponseAmount = appraisal?.appraisal?.amount
      this.appraisalResponseAmountConsignment =
        appraisal?.appraisalConsignment?.amount
    }

    this.attributes = await this.fetchData({
      query: { name: 'find', model: 'DealAutoAttribute' },
      filter: {
        id_process_record: { _eq: appraisal?.id },
        process: { table_name: { _eq: 'appraisal' } },
      },
      force: true,
    })

    this.alternativeAttributes = await this.findAlternativesAttributes(
      this.appraisal.deal.auto
    )
  }

  setAutoValues (appraisal) {
    const {
      dealNotMatch,
      dealNotQualify,
      inspectionExpired,
      appraisalNotQualify,
      backup,
    } = this

    if (!(dealNotMatch || dealNotQualify)) {
      this.formData.auto = backup?.auto || appraisal?.deal?.auto
      this.fields.auto.items = [backup?.auto || appraisal?.deal?.auto]

      const isCav =
        !appraisal?.status?.isCavNotMatch &&
        !appraisal?.status?.isValidCav &&
        !appraisal?.status?.readingFile
      if (
        !appraisal?.status?.isAppraised &&
        !appraisal?.status?.isAppealed &&
        isCav &&
        !appraisal?.agreedAmount
      ) {
        const isExpectedOffer =
          appraisal?.responses[0]?.type?.name.includes('expected_offer')
        this.formData.price = isExpectedOffer
          ? appraisal?.responses?.find(
            response => response.type.name === 'expected_offer'
          )?.amount
          : null
        this.formData.comment = isExpectedOffer
          ? appraisal?.responses?.find(
            response => response.type.name === 'expected_offer'
          )?.comment
          : null
        this.formData.priceConsignment = isExpectedOffer
          ? appraisal?.responses?.find(
            response => response.type.name === 'expected_offer_consignment'
          )?.amount
          : null
        this.formData.commentConsignment = isExpectedOffer
          ? appraisal?.responses?.find(
            response => response.type.name === 'expected_offer_consignment'
          )?.comment
          : null
      }

      if (
        !(appraisalNotQualify || inspectionExpired) &&
        !this.formData.agreementPrice
      ) {
        this.formData.agreementPrice = appraisal.agreedAmount
      }
    }
  }

  async setFormData (appraisal, attributes) {
    this.setAutoValues(appraisal)

    const { metadataCollection } = this
    const { fields } = metadataCollection as Form

    const offer1 = appraisal?.externalOffers?.filter(
      offer => offer.company?.id === fields.offer2?.init?.value?.company?.id
    )
    const offer2 = appraisal?.externalOffers?.filter(
      offer => offer.company?.id === fields.offer1?.init?.value?.company?.id
    )
    this.setOffers(offer1, offer2)
    const km = attributes?.filter(
      attribute => attribute?.component?.id === this.km?.[0]?.id
    )[0]
    this.formData.kms = km?.value ?? null

    const owner = attributes?.filter(
      attribute => attribute?.component?.id === this.owner?.[0]?.id
    )[0]
    this.formData.owner = owner?.componentValue?.id ?? null

    this.formData.maintenance = appraisal?.deal?.auto?.maintenances?.length
      ? appraisal?.deal?.auto?.maintenances
        ?.filter(maintenance => maintenance.status.isDone)
        .map(maintenance => maintenance?.type)
      : [{ id: undefined }]
    this.formData.prepaidMaintenance = appraisal?.deal?.auto?.maintenances
      ?.filter(maintenance => maintenance.status.isPrepaid)
      .map(maintenance => maintenance?.type)
    if (this.formData.maintenance?.length) {
      const max = this.formData.maintenance.reduce((prev, current) => {
        return prev.mileage > current.mileage ? prev : current
      })

      this.fields.maintenance.items = await this.fetchData({
        query: { name: 'find', model: 'MaintenanceType' },
        filter: { mileage: { _lte: max.mileage + 20000 } },
      })

      this.fields.maintenance.items.unshift({
        id: undefined,
        description: 'Sin registro',
      })
    }

    await this.setFetchedFormData(appraisal)
  }

  get dealNotMatch () {
    const { appraisal } = this

    return appraisal?.deal?.closingReason?.name === 'not_match'
  }

  get dealNotQualify () {
    const { appraisal } = this

    return appraisal?.deal?.closingReason?.name === 'not_qualify'
  }

  get inspectionExpired () {
    return this.inspection?.closingReason?.isExpired
  }

  get disabledDeal () {
    return this.dealNotMatch || this.dealNotQualify || this.inspectionExpired
  }

  get appraisalNotQualify () {
    const { appraisal } = this

    return appraisal?.status?.isClosed && !appraisal?.inspection?.id
  }

  get autoNotMatch () {
    const { appraisal } = this

    if (!appraisal?.id) return true

    return (
      appraisal?.deal?.auto?.status?.isNotMatch ||
      appraisal?.deal?.auto?.status?.isDeprecated
    )
  }

  get appraisalValues () {
    const {
      appraisal,
      km,
      owner,
      attributes,
      loading,
      dealNotMatch,
      dealNotQualify,
      formData,
    } = this

    return {
      appraisal,
      km,
      owner,
      attributes,
      loading,
      dealNotMatch,
      dealNotQualify,
      auto: formData?.auto,
    }
  }

  @Watch('appraisalValues', { immediate: true, deep: true })
  @Debounce(1000)
  async onAppraisalValuesChange (val) {
    const isSameAuto =
      val?.auto &&
      val.appraisal?.deal?.auto &&
      val?.auto?.registrationPlate ===
      val.appraisal?.deal?.auto?.registrationPlate

    if (val.dealNotQualify) {
      this.loading = false
      return
    }

    if (val.dealNotMatch && !val.auto && !isSameAuto) {
      this.loading = false
      return
    }

    if (val?.appraisal?.id) {
      const inspection = await this.fetchData({
        query: { name: 'find', model: 'Inspection' },
        filter: { id_appraisal: { _eq: val.appraisal.id } },
      })
      this.inspection = inspection?.[0]
    }

    if (
      val.appraisal?.id &&
      val.attributes?.length &&
      val.km?.length &&
      val.owner?.length &&
      val.loading
    ) {
      await this.setFormData(val.appraisal, val.attributes)
      await this.setDetails()
      this.loading = false
    }
  }

  async findAlternativesAttributes (auto) {
    if (!auto?.version?.version?.id || !auto?.version?.year?.id) return

    let getGenerations = await this.getGeneration(
      auto?.version?.version?.id,
      auto?.version?.year?.id
    )

    let generation =
      getGenerations?.length === 1
        ? getGenerations[0]
        : getGenerations.find(
          generation => generation.id === auto.generation?.id
        )

    if (generation?.attributes?.length) {
      return generation?.attributes
    }

    const attributes = await this.fetchData({
      query: {
        name: 'find',
        model: 'Attribute',
      },
      filter: {
        id_version_year: { _eq: auto.version.id },
      },
    })

    if (attributes?.length) {
      return attributes
    }

    if (!auto?.generation?.sku) {
      return
    }

    let cont = 1
    while (cont <= 3 && !generation?.attributes?.length) {
      getGenerations = await this.getGeneration(
        auto?.version?.version?.id,
        auto?.version?.year?.id - cont
      )

      generation =
        getGenerations?.length === 1
          ? getGenerations[0]
          : getGenerations.find(
            generation => generation.sku === auto.generation?.sku
          )

      if (generation?.attributes?.length) {
        this.attributes = generation?.attributes || []
        break
      } else {
        cont++
      }
    }

    return generation?.attributes || []
  }

  setOffers (offer1, offer2) {
    const {
      formData: { auto },
      disabledDeal,
    } = this

    if (disabledDeal && auto) return
    this.formData.offer1 = offer1?.length ? offer1[0]?.amount : null
    this.formData.offer2 = offer2?.length ? offer2[0]?.amount : null
    this.hasOffer1 = Boolean(offer1?.length)
    this.hasOffer2 = Boolean(offer2?.length)
  }

  async setFetchedFormData (appraisal) {
    const { idProcess, disabledDeal, appraisalNotQualify } = this

    if (
      appraisal?.status?.isAppealed &&
      (appraisal?.appeal || appraisal?.appealConsignment) &&
      !disabledDeal
    ) {
      this.formData.price = appraisal?.responses?.find(
        response => response.type.name === 'appealed'
      )?.amount
      this.formData.comment = appraisal?.responses?.find(
        response => response.type.name === 'appealed'
      )?.comment
      this.formData.priceConsignment = appraisal?.responses?.find(
        response => response.type.name === 'appealed_consignment'
      )?.amount
      this.formData.commentConsignment = appraisal?.responses?.find(
        response => response.type.name === 'appealed_consignment'
      )?.comment
    }

    const photos = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: appraisal.id } },
          { parameter: { process: { id: { _eq: idProcess } } } },
          { parameter: { file_type: { name: { _eq: 'photo' } } } },
        ],
      },
      force: true,
    })

    const cav = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: appraisal.id } },
          { parameter: { process: { id: { _eq: idProcess } } } },
          { parameter: { file_type: { name: { _eq: 'cav' } } } },
        ],
      },
      force: true,
    })

    const report = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: appraisal.id } },
          { parameter: { process: { id: { _eq: idProcess } } } },
          { parameter: { file_type: { name: { _eq: 'legal_report' } } } },
        ],
      },
      force: true,
    })

    this.formData.photos = photos
    if (!(disabledDeal || appraisalNotQualify)) {
      this.formData.cav = cav?.length ? cav : []
    }

    this.formData.document = report?.length ? [report[0]] : []

    if (cav.length && !(disabledDeal || appraisalNotQualify)) {
      this.appraisal.cavValidation = {
        expirationDate: cav[0].file.expirationDate,
        validations: cav[0].validation,
      }
    }
    await this.setDetails()
  }

  async setMetadata () {
    const { metadata } = this.getForm('Appraisal', 'appraisal')
    const { fields, form } = metadata as Form
    this.metadataCollection = metadata
    this.title = form.title
    this.fields.linkLegalReport.properties = fields.linkLegalReport.properties
    this.fields.offer1.properties.label =
      fields.offer2?.init.value.company.alias
    this.fields.offer2.properties.label =
      fields.offer1?.init.value.company.alias
    this.formData.companies = {
      offer1: fields.offer1.init.value.company,
      offer2: fields.offer2.init.value.company,
    }
    this.maxPrepaidMaintenances =
      fields.config.init.value.maxPrepaidMaintenances

    const { webSite: webSite1 } = await this.fetchData({
      query: {
        name: 'fetch',
        model: 'Person',
        params: { id: fields.offer2?.init.value.company.id },
      },
    })
    const { webSite: webSite2 } = await this.fetchData({
      query: {
        name: 'fetch',
        model: 'Person',
        params: { id: fields.offer1?.init.value.company.id },
      },
    })
    this.fields.offer1.properties.link = webSite1
    this.fields.offer2.properties.link = webSite2

    this.fields.owner.properties.label =
      fields.owners?.init.value.component.name

    Object.assign(this.fields.auto.properties, fields.auto?.properties)

    const component = await this.fetchData({
      query: { name: 'find', model: 'Component' },
      filter: { id: { _eq: fields.owners?.init?.value.component.id } },
    })

    this.fields.owner.items = component[0]?.values?.map(val => {
      return {
        description: val.value,
        id: val.id,
      }
    })

    await this.setFilesFieldsData()
  }

  async setFilesFieldsData () {
    const fileInfo = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { process: { table_name: { _eq: 'appraisal' } } },
    })

    const photos = fileInfo.find(
      fileParameter => fileParameter.fileType.isPhoto
    )
    const cav = fileInfo.find(fileParameter => fileParameter.fileType.isCav)
    const document = fileInfo.find(
      fileParameter => fileParameter.fileType.isLegalReport
    )

    this.fields.photos.properties.accept = photos?.fileType?.mimes
    this.fields.photos.properties.multiple = photos?.multiple
    this.fields.photos.properties.fileTypeId = photos?.fileType?.id
    this.fields.photos.properties.name = photos?.name
    this.fields.cav.properties.accept = cav?.fileType.mimes
    this.fields.cav.properties.multiple = cav?.multiple
    this.fields.cav.properties.fileTypeId = cav?.fileType?.id
    this.fields.cav.properties.name = cav?.name
    this.fields.document.properties.accept = document?.fileType.mimes
    this.fields.document.properties.fileTypeId = document?.fileType.id
    this.fields.document.properties.multiple = document?.multiple
    this.fields.document.properties.name = document?.name

    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'appraisal' } },
    })

    this.idProcess = process[0].id
  }

  setTheBackup () {
    const { backup } = this

    if (!backup) return

    if ('appraisalForm' in backup && backup.appraisalForm) {
      this.formData = backup.appraisalForm
    }
    if ('auto' in backup && backup.auto) {
      this.formData.auto = backup.auto
    }
  }

  saveBackup () {
    const { backup, formData, fields, idProcess } = this
    if (backup) {
      formData.maintenance = formData?.maintenance?.sort(
        (a, b) => b.mileage - a.mileage
      )
      backup.appraisalForm = { ...formData, fields, idProcess }
      if (!backup?.auto && formData.auto instanceof Auto) {
        backup.auto = formData.auto
      }
      this.setBackup(backup)
    } else {
      const auto = formData.auto instanceof Auto ? formData.auto : null
      this.setBackup({
        appraisalForm: { ...formData, fields, idProcess },
        auto,
      })
    }
  }

  async newDeal (backup, deal) {
    const isAuto = 'auto' in backup && backup.auto

    const auto = await this.fetchData({
      query: { name: 'fetch', model: 'Auto', params: { id: deal?.auto?.id } },
    })

    const generationDifferent =
      auto?.generation?.id !== isAuto?.generation?.id && isAuto?.generation?.id
    const versionDifferent =
      auto?.version?.id !== isAuto?.version?.id && isAuto?.version?.id

    return generationDifferent || versionDifferent
  }

  async updateAppraisal (auto) {
    const { formData: appraisalForm, appraisal } = this
    const appraisalFetched = await this.fetchData({
      query: {
        name: 'fetch',
        model: 'Appraisal',
        params: { id: appraisal?.id },
      },
      force: true,
    })

    const fields = {
      id: appraisalFetched?.deal?.auto?.id,
      id_version_year: auto?.version?.id || appraisalForm?.auto?.version?.id,
      id_generation:
        auto?.generation?.id || appraisalForm?.auto?.generation?.id || null,
    }

    if (fields?.id_generation) {
      delete fields.id_version_year
    }

    this.formData.auto = await this.pushData({
      model: 'Auto',
      fields,
    })

    if (appraisalFetched?.detail?.id) {
      await this.removeData({
        model: 'AppraisalDetail',
        fields: { id: appraisalFetched?.detail?.id },
      })
    }
    this.saveBackup()
    await this.automaticAppraisal(appraisal?.id)
  }

  async send () {
    const autoForm = this.$refs.fieldAuto
    const isCommentOver =
      this.formData?.comment?.length > 250 ||
      this.formData?.commentConsignment?.length > 250
    const errorMessage = this.errorMessageConsignment.length
    if (
      (autoForm && !autoForm?.$refs.form.validate()) ||
      !this.$refs.form.validate() ||
      isCommentOver ||
      !this.formData?.kms ||
      errorMessage
    ) {
      return
    }
    this.loadingForm = true
    this.saveBackup()

    const {
      appraisal: { deal, closingReason, id },
      formData,
      dealNotMatch,
      dealNotQualify,
      inspectionExpired,
      lead,
      backup,
      appraisal,
    } = this

    const isAuto = 'auto' in backup && backup.auto
    const createNewAppraisal = appraisal?.status?.isNotOffer
    const createNewDeal = id && (await this.newDeal(backup, deal))

    if (!id && !lead?.id) {
      await this.close()
      return
    }

    if (createNewDeal && createNewAppraisal) {
      await this.updateAppraisal(backup.auto)
    } else if (
      dealNotMatch ||
      dealNotQualify ||
      (createNewDeal && !createNewAppraisal)
    ) {
      await this.sendNewDeal(this, isAuto)
    } else if (
      closingReason?.isExpired ||
      closingReason?.notQualify ||
      inspectionExpired
    ) {
      await this.sendNewAppraisal(id, deal.id, formData)
    } else if (!deal?.id && lead.id && !lead?.purchase) {
      await this.createAppraisal(this)
    } else {
      await this.handleAppraisal()
    }

    await this.close()
  }

  async handleAppraisal () {
    const {
      appraisal: { id, externalOffers, responses, status, deal },
      formData,
      appraisalAppraised,
      attributes,
    } = this
    const { auto, maintenance, prepaidMaintenance } = formData

    if (auto?.id) {
      await this.insertUpdateMaintenances(
        maintenance,
        prepaidMaintenance,
        auto
      )
    }

    if (id && !status?.isClosed) {
      if (formData.kms) {
        await this.insertUpdateDealAutoAttributeKms(
          id,
          deal?.id,
          formData,
          'kms',
          this.km[0]?.id
        )
      }

      const owner = attributes?.filter(
        attribute => attribute?.component?.id === this.owner[0]?.id
      )[0]

      if (formData.owner && formData.owner !== owner?.componentValue?.id) {
        await this.insertUpdateDealAutoAttributeOwner(
          id,
          deal?.id,
          formData,
          'owner',
          this.owner[0]?.id
        )
      }

      if (status.isPending && formData.offer1 && formData.offer2) {
        await this.pushData({
          model: 'Appraisal',
          fields: {
            id,
            id_process_status: this.statusAppraisal.noOffer[0]?.id,
          },
        })
      }
      if (appraisalAppraised && (formData.price || formData.priceConsignment)) {
        await this.pushData({
          model: 'Appraisal',
          fields: {
            id,
            id_process_status: this.statusAppraisal.appealed[0]?.id,
          },
        })
      }

      await this.sendOtherInfo(formData, externalOffers, responses, id)
    } else if (id) {
      await this.sendNewAppraisal(id, deal?.id, formData)
    }
  }

  async sendOtherInfo (formData, externalOffers, responses, id) {
    await this.pushData({
      model: 'Appraisal',
      fields: {
        id,
        agreed_amount:
          formData.agreementPrice !== '' ? formData.agreementPrice : null,
      },
    })

    await this.insertUpdateExternalOffer(externalOffers, formData, id)
    await this.insertUpdateResponse(responses, formData, id)
    await this.insertUpdateFile()
  }

  async insertUpdateFile () {
    const {
      idProcess,
      formData: { cav, document, photos, linkLegalReport },
      fields: { cav: cavField, document: documentField, photos: photosField },
      appraisal: {
        id,
        deal: { id: idDeal },
      },
    } = this

    if (cav?.length) {
      const file = await this.handleFileType(cav, cavField, idProcess, id)

      if (file?.length) {
        const fileProcess = file[0] as FileProcess
        if (fileProcess?.id) {
          await this.fileProcessing({ idDeal, idFileProcess: fileProcess?.id })
        }
      }
    }
    if (document?.length) {
      await this.handleFileType(document, documentField, idProcess, id)
      const fileId = this.isArrayFiles(document)
        ? document[0]?.id
        : document?.[0]?.file?.id
      const fields = { id: fileId, source_link: linkLegalReport }
      await this.pushData({ model: 'File', fields })
    }
    if (photos?.length) {
      await this.handleFileType(photos, photosField, idProcess, id)
    }
  }

  async allowFile (files) {
    if (!files?.length) return []
    const { appraisal } = this

    const process = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_file: { _in: files } },
          { id_process_record: { _eq: appraisal.id } },
        ],
      },
      force: true,
    })

    return files.filter(
      file => !process.some(fileProcess => fileProcess.file.id === file)
    )
  }

  async insertUpdateDealAutoAttributeOwner (
    id,
    idDeal,
    formData,
    field,
    componentId
  ) {
    const { idProcess } = this
    const dealAutoAttribute = await this.fetchData({
      query: { name: 'find', model: 'DealAutoAttribute' },
      filter: {
        _and: [
          { id_component: { _eq: componentId } },
          { id_process: { _eq: idProcess } },
          { id_process_record: { _eq: id } },
          { id_deal: { _eq: idDeal } },
        ],
      },
      force: true,
    })

    if (dealAutoAttribute?.length) {
      if (dealAutoAttribute[0].value !== formData[field]) {
        const id = dealAutoAttribute[0].id
        const fields = {
          id_component_value: formData[field],
          id,
        }
        await this.pushData({ model: 'DealAutoAttribute', fields })
      }
    } else {
      await this.pushData({
        model: 'DealAutoAttribute',
        fields: {
          id_deal: idDeal,
          id_component_value: formData[field],
          id_component: componentId,
          id_process: idProcess,
          id_process_record: id,
        },
      })
    }
  }

  async insertUpdateDealAutoAttributeKms (
    id,
    idDeal,
    formData,
    field,
    componentId
  ) {
    const { idProcess } = this
    const dealAutoAttribute = await this.fetchData({
      query: { name: 'find', model: 'DealAutoAttribute' },
      filter: {
        _and: [
          { id_component: { _eq: componentId } },
          { id_process: { _eq: idProcess } },
          { id_process_record: { _eq: id } },
          { id_deal: { _eq: idDeal } },
        ],
      },
      force: true,
    })

    if (dealAutoAttribute?.length) {
      if (dealAutoAttribute[0].value !== formData[field]) {
        const id = dealAutoAttribute[0].id
        const fields = {
          value: formData[field],
          id,
        }
        await this.pushData({ model: 'DealAutoAttribute', fields })
      }
    } else {
      await this.pushData({
        model: 'DealAutoAttribute',
        fields: {
          id_process_record: id,
          value: formData[field],
          id_process: idProcess,
          id_deal: idDeal,
          id_component: componentId,
        },
      })
    }
  }

  async updateAuto (auto) {
    const {
      appraisal: {
        deal: { auto: appraisalAuto },
      },
    } = this

    const fields = {
      id: appraisalAuto.id,
      registration_plate:
        auto?.registrationPlate !== appraisalAuto?.registrationPlate
          ? auto?.registrationPlate
          : null,
      id_version_year:
        auto?.version?.id !== appraisalAuto?.version?.id
          ? auto?.version?.id
          : null,
    }
    if (!fields?.registration_plate) {
      delete fields.registration_plate
    }
    if (!fields?.id_version_year) {
      delete fields.id_version_year
    }

    await this.pushData({ model: 'Auto', fields })
  }

  async addMaintenances (maintenance, prepaidMaintenance, auto) {
    let vehicle = null

    if (auto?.id) {
      vehicle = await this.fetchData({
        query: { name: 'fetch', model: 'Auto', params: { id: auto.id } },
      })
    }

    const autoMaintenance = vehicle?.maintenances

    if (!autoMaintenance?.length && auto?.id) {
      await this.insertMaintenance(maintenance, auto)
      await this.insertMaintenance(prepaidMaintenance, auto, 'prepaid')
      return
    }

    const newMaintenancesValue = this.getMaintenances(
      maintenance,
      autoMaintenance
    )
    const newPrepaidMaintenancesValue = this.getMaintenances(
      prepaidMaintenance,
      autoMaintenance
    )

    if (newMaintenancesValue?.length) {
      await this.insertMaintenance(newMaintenancesValue, auto)
    }

    if (newPrepaidMaintenancesValue?.length) {
      await this.insertMaintenance(
        newPrepaidMaintenancesValue,
        auto,
        'prepaid'
      )
    }
  }

  async insertUpdateMaintenances (maintenance, prepaidMaintenance, auto) {
    const { maintenances: autoMaintenance } = auto

    let deleteMaintenancesValues = autoMaintenance
      ?.filter(_ => _?.id)
      ?.filter(
        maintenanceOriginal =>
          !maintenance?.some(
            newMaintenance =>
              newMaintenance.id === maintenanceOriginal?.type?.id
          )
      )

    let deletePrepaidMaintenancesValues = autoMaintenance
      ?.filter(_ => _?.id)
      ?.filter(
        maintenanceOriginal =>
          !prepaidMaintenance?.some(
            newMaintenance =>
              newMaintenance.id === maintenanceOriginal?.type?.id
          )
      )

    const editPrepaidMaintenances = deleteMaintenancesValues?.filter(
      maintenanceOriginal =>
        prepaidMaintenance?.some(
          newMaintenance =>
            newMaintenance.id === maintenanceOriginal?.type?.id
        )
    )

    const editMaintenances = deletePrepaidMaintenancesValues?.filter(
      maintenanceOriginal =>
        maintenance?.some(
          newMaintenance =>
            newMaintenance.id === maintenanceOriginal?.type?.id
        )
    )

    deleteMaintenancesValues = deleteMaintenancesValues?.filter(
      maintenanceOriginal =>
        !editPrepaidMaintenances?.some(
          editedMaintenance => editedMaintenance.id === maintenanceOriginal.id
        )
    )

    deletePrepaidMaintenancesValues = deletePrepaidMaintenancesValues?.filter(
      maintenanceOriginal =>
        !editMaintenances?.some(
          editedMaintenance => editedMaintenance.id === maintenanceOriginal.id
        )
    )

    if (editMaintenances?.length) {
      await this.editMaintenance(editMaintenances, auto)
    }

    if (editPrepaidMaintenances?.length) {
      await this.editMaintenance(editPrepaidMaintenances, auto, 'prepaid')
    }

    if (deleteMaintenancesValues?.length) {
      await this.removeMaintenance(deleteMaintenancesValues)
    }

    if (deletePrepaidMaintenancesValues?.length) {
      await this.removeMaintenance(deletePrepaidMaintenancesValues)
    }

    await this.addMaintenances(maintenance, prepaidMaintenance, auto)
  }

  async editMaintenance (maintenance, auto, type = 'done') {
    const status = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { status: { name: { _eq: type } } },
    })
    await Promise.all(
      maintenance.map(item =>
        this.pushData({
          model: 'Maintenance',
          fields: { id: item.id, id_process_status: status[0].id },
        })
      )
    )
  }

  async removeMaintenance (maintenanceValues) {
    await Promise.all(
      maintenanceValues.map(item =>
        this.removeData({
          model: 'Maintenance',
          fields: { id: item.id },
        })
      )
    )
  }

  getMaintenances (maintenance, autoMaintenance) {
    return maintenance?.filter(
      maintenanc =>
        !autoMaintenance?.some(
          maintenanceOriginal =>
            maintenanceOriginal?.type?.id === maintenanc.id
        )
    )
  }

  async insertMaintenance (maintenance, auto, type = 'done') {
    const status = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { status: { name: { _eq: type } } },
    })
    const { appraisal } = this
    const existingIds = appraisal.deal.auto?.maintenances?.map(
      maintenance => maintenance.id
    )
    const filteredMaintenances = maintenance
      ?.filter(_ => _?.id)
      ?.filter(item => !existingIds?.includes(item.id))

    if (filteredMaintenances?.length) {
      await Promise.all(
        maintenance.map(item =>
          this.pushData({
            model: 'Maintenance',
            fields: {
              id_auto: auto.id,
              id_maintenance_type: item.id,
              id_process_status: status[0].id,
            },
          })
        )
      )
    }
  }

  calculateInitialValues (
    responses,
    formData,
    id,
    appraisalAppraised,
    displayPurchase
  ) {
    const sortedResponse = _.cloneDeep(responses).sort(
      (a, b) => Number(b.id) - Number(a.id)
    )
    const isAppealed = responses.some(response =>
      response.type.name.includes('appealed')
    )
    const responseType = !isAppealed ? 'expected_offer' : 'appealed'
    const responseTypeConsignment = !isAppealed
      ? 'expected_offer_consignment'
      : 'appealed_consignment'

    const responsesFiltered = sortedResponse.filter(
      response => response.type.name === responseType
    )
    const response = responsesFiltered.length > 0 ? responsesFiltered[0] : null

    const responsesConsignmentFiltered = sortedResponse.filter(
      response => response.type.name === responseTypeConsignment
    )
    const responseConsignment =
      responsesConsignmentFiltered.length > 0
        ? responsesConsignmentFiltered[0]
        : null

    return {
      response,
      responseConsignment,
    }
  }

  async insertUpdateResponse (responses, formData, id) {
    const {
      appraisalAppraised,
      appraisalAppealed,
      appraisal,
      displayPurchase,
    } = this

    if (
      !appraisalAppraised &&
      !appraisalAppealed &&
      !appraisal.status.isNotOffer
    ) {
      return
    }
    const { response, responseConsignment } = this.calculateInitialValues(
      responses,
      formData,
      id,
      this.appraisalAppraised,
      this.displayPurchase
    )

    if (formData.priceConsignment) {
      if (responseConsignment?.id && !appraisalAppraised) {
        await this.pushData({
          model: 'AppraisalResponse',
          fields: {
            id: responseConsignment.id,
            amount: formData.priceConsignment,
            comment: formData.commentConsignment,
            id_employee: this.idEmployee,
          },
        })
      } else if (!responseConsignment?.id && !appraisalAppraised) {
        await this.pushData({
          model: 'AppraisalResponse',
          fields: {
            id_appraisal: id,
            amount: formData.priceConsignment,
            type: 'expected_offer_consignment',
            id_employee: this.idEmployee,
            comment: formData.commentConsignment,
          },
        })
      } else {
        await this.pushData({
          model: 'AppraisalResponse',
          fields: {
            id_appraisal: id,
            amount: formData.priceConsignment,
            type: 'appealed_consignment',
            id_employee: this.idEmployee,
            comment: formData.comment,
          },
        })
      }
    }

    if (formData.price && displayPurchase) {
      if (response?.id && !appraisalAppraised) {
        await this.pushData({
          model: 'AppraisalResponse',
          fields: {
            id: response.id,
            amount: formData.price,
            comment: formData.comment,
            id_employee: this.idEmployee,
          },
        })
      } else if (!response?.id && !appraisalAppraised) {
        await this.pushData({
          model: 'AppraisalResponse',
          fields: {
            id_appraisal: id,
            amount: formData.price,
            type: 'expected_offer',
            id_employee: this.idEmployee,
            comment: formData.comment,
          },
        })
      } else {
        await this.pushData({
          model: 'AppraisalResponse',
          fields: {
            id_appraisal: id,
            amount: formData.price,
            type: 'appealed',
            id_employee: this.idEmployee,
            comment: formData.comment,
          },
        })
      }
    }

    if (
      responseConsignment?.amount !== formData.priceConsignment &&
      formData.priceConsignment
    ) {
      if (responseConsignment?.id && !appraisalAppraised) {
        await this.pushData({
          model: 'AppraisalResponse',
          fields: {
            id: responseConsignment.id,
            amount: formData.priceConsignment,
            comment: formData.commentConsignment,
            id_employee: this.idEmployee,
          },
        })
      } else if (!responseConsignment?.id && !appraisalAppraised) {
        await this.pushData({
          model: 'AppraisalResponse',
          fields: {
            id_appraisal: id,
            amount: formData.priceConsignment,
            type: 'expected_offer_consignment',
            id_employee: this.idEmployee,
            comment: formData.commentConsignment,
          },
        })
      } else {
        await this.pushData({
          model: 'AppraisalResponse',
          fields: {
            id_appraisal: id,
            amount: formData.priceConsignment,
            type: 'appealed_consignment',
            id_employee: this.idEmployee,
            comment: formData.comment,
          },
        })
      }
    }
  }

  async insertUpdateExternalOffer (externalOffers, formData, id) {
    const offer1 = externalOffers.filter(
      offer => offer.company?.id === formData.companies.offer1?.id
    )[0]

    if (offer1?.amount !== formData.offer1 && formData.offer1) {
      if (offer1?.id) {
        await this.pushData({
          model: 'AppraisalExternalOffer',
          fields: { id: offer1.id, amount: formData.offer1 },
        })
      } else if (formData.offer1) {
        await this.pushData({
          model: 'AppraisalExternalOffer',
          fields: {
            id_appraisal: id,
            amount: formData.offer1,
            id_person: formData.companies.offer1?.id,
          },
        })
      }
    }

    const offer2 = externalOffers.filter(
      offer => offer.company?.id === formData.companies.offer2?.id
    )[0]

    if (offer2?.amount !== formData.offer2 && formData.offer2) {
      if (offer2?.id) {
        await this.pushData({
          model: 'AppraisalExternalOffer',
          fields: { id: offer2.id, amount: formData.offer2 },
        })
      } else if (formData.offer2) {
        await this.pushData({
          model: 'AppraisalExternalOffer',
          fields: {
            id_appraisal: id,
            amount: formData.offer2,
            id_person: formData.companies.offer2?.id,
          },
        })
      }
    }
  }

  get bindAuto () {
    const {
      fields: { auto },
      disabledDeal,
    } = this

    if (!auto && disabledDeal) return {}

    return { ...auto.properties, items: auto.items }
  }

  get appealedRule () {
    const { appraisalHasResponseAppraised, isAgreementAmount } = this

    return appraisalHasResponseAppraised || isAgreementAmount
      ? this.fieldRequired
      : []
  }

  get appraisalHasResponseAppraised () {
    const {
      appraisal,
      formData: { price },
    } = this

    return (
      appraisal?.responses?.some(response => response.isAppraised) &&
      Boolean(price)
    )
  }

  get isAgreementAmount () {
    const {
      formData: { agreementPrice },
    } = this

    return Boolean(agreementPrice)
  }

  get disabledChanges () {
    const { appraisal, disabledDeal } = this

    if (!appraisal?.closingReason) {
      return false
    }

    if (disabledDeal) {
      return false
    }

    return !['expired', 'not_qualify'].includes(appraisal?.closingReason?.name)
  }

  get appraisalDisabled () {
    const { appraisal, disabledDeal } = this

    if (disabledDeal) {
      return false
    }

    return (
      !appraisal?.status?.isPending &&
      !appraisal?.status?.isNotOffer &&
      !appraisal?.status?.isClosed &&
      Boolean(appraisal?.id)
    )
  }

  get disabledOwnerField () {
    const { attributes, owner } = this

    const ownerFind = attributes?.filter(
      attribute => attribute?.component?.id === owner?.[0]?.id
    )[0]

    return Boolean(ownerFind?.componentValue?.id)
  }

  get appraisalAppraised () {
    const { appraisal } = this

    return appraisal?.status?.isAppraised
  }

  get appraisalAppealed () {
    const { appraisal } = this

    return appraisal?.status?.isAppealed
  }

  get icon () {
    const {
      backup,
      formData: { auto },
    } = this
    let icon = 'mdi-plus-circle-outline'

    if ((backup && 'auto' in backup && backup.auto) || auto) {
      icon = 'mdi-pencil'
    }

    return icon
  }

  get isMaintenance () {
    const {
      formData: { maintenance },
    } = this
    return maintenance?.length
  }

  get isMaintenancePrepaid () {
    const {
      maxPrepaidMaintenances,
      fields,
      formData: { kms, prepaidMaintenance },
    } = this

    if (prepaidMaintenance?.length && kms) {
      return true
    }

    return (
      kms < maxPrepaidMaintenances && fields.prepaidMaintenance.items.length
    )
  }

  findIndexMaintenance (selection) {
    if (!selection) return -1
    const {
      fields: {
        maintenance: { items },
      },
    } = this
    if (!items?.length) return -1

    const orderSelection = deepCopy(selection).sort((a, b) => a.id - b.id)
    const val = orderSelection[selection.length - 1]

    return selection.findIndex(item => item.id === val.id)
  }

  findIndexMaintenancePrepaid (selection) {
    if (!selection) return -1
    const {
      fields: {
        prepaidMaintenance: { items },
      },
    } = this
    if (!items?.length) return -1

    const orderSelection = deepCopy(selection).sort((a, b) => a.id - b.id)
    const val = orderSelection[selection.length - 1]

    return selection.findIndex(item => item.id === val.id)
  }

  async clearAuto () {
    const { backup } = this
    if (backup && 'auto' in backup && backup.auto) {
      this.backup.auto = null
    }
    this.fields.auto.items = []
    this.formData.auto = null
    if (this?.appraisal?.deal?.auto) {
      this.appraisal.deal.auto = null
    }
    await this.setDetails()
  }

  async closeAppraisalNewAuto () {
    const notMatchStatus = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: {
        _and: [
          { process: { table_name: { _eq: 'auto' } } },
          { status: { name: { _eq: 'not_match' } } },
        ],
      },
    })
    const autos = await this.fetchData({
      query: { name: 'find', model: 'Auto', order: { id: 'desc' } },
      filter: { id: { _eq: this.appraisal?.deal?.auto?.id } },
    })

    const autosBuying = autos?.filter(auto => auto?.status?.name === 'buying')

    await this.pushData({
      model: 'Auto',
      fields: {
        id: autosBuying[0]?.id,
        id_process_status: notMatchStatus?.[0]?.id,
      },
    })

    const closingReason = await this.fetchData({
      query: { name: 'find', model: 'ClosingReason' },
      filter: {
        _and: [
          { type: { name: { _eq: 'not_qualify' } } },
          { status: { process: { table_name: { _eq: 'appraisal' } } } },
        ],
      },
    })
    await this.pushData({
      model: 'Appraisal',
      fields: {
        id: this.appraisal.id,
        id_process_status: this.statusAppraisal.closed[0]?.id,
        id_closing_reason: closingReason[0]?.id,
      },
    })

    const dealNotMatchStatus = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: {
        _and: [
          { process: { table_name: { _eq: 'deal' } } },
          { status: { name: { _eq: 'lost' } } },
        ],
      },
    })

    const closeReason = await this.fetchData({
      query: { name: 'find', model: 'ClosingReason' },
      filter: {
        _and: [
          { type: { name: { _eq: 'not_match' } } },
          { status: { process: { table_name: { _eq: 'deal' } } } },
        ],
      },
    })

    if (this.appraisal?.deal?.id) {
    await this.pushData({
      model: 'Deal',
      fields: {
        id: this.appraisal?.deal?.id,
        id_process_status: dealNotMatchStatus?.[0]?.id,
        id_closing_reason: closeReason?.[0]?.id,
      },
    })
    }
  }

  async confirmAction () {
    this.loadingForm = true
    this.appraisalAlert.open = false
    await this.closeAppraisalNewAuto()
    await this.redirect()
  }

  async redirect () {
    this.saveBackup()
    const { appraisal } = this
    const { deal } = appraisal

    const appraisalId = appraisal?.id?.toString() || 'create'
    const autoId = deal?.auto?.id?.toString() || null

    await this.$router
      .push({
        name: 'generic-auto',
        params: { model: 'Appraisal', uid: appraisalId, id: autoId },
      })
      .catch(() => {
      })
  }

  async openAuto () {
    if (this.appraisal?.id && !this.appraisal?.closingReason?.id) {
      this.appraisalAlert.open = true
      return
    }
    await this.redirect()
  }

  async setAppraisalValues (appraisal) {
    const { formData: { auto }, backup } = this
    if (appraisal && !appraisal?.deal) {
      appraisal.deal = plainToInstance(Deal, {})
      if (auto instanceof Auto) {
        appraisal.deal.auto = backup?.auto || auto
      }
    } else {
      if (auto instanceof Auto) {
        appraisal.deal.auto = backup?.auto || auto
      }
    }

    if (appraisal?.appraiser?.id) {
      const person = await this.fetchData({
        query: { name: 'find', model: 'Person' },
        filter: { employee: { id: { _eq: appraisal.appraiser.id } } },
        force: true,
      })
      appraisal.appraiser.person = person[0]
    }

    if (appraisal?.deal?.lead?.id) {
      const deals = await this.fetchData({
        query: { name: 'find', model: 'Deal' },
        filter: { id_lead: { _eq: appraisal.deal.lead.id } },
      })

      const sale = deals?.find(deal => deal.isSale)
      if (sale) {
        const auto = await this.fetchData({
          query: { name: 'find', model: 'Auto' },
          filter: { id: { _eq: sale?.auto?.id } },
        })

        const stock = await this.fetchData({
          query: { name: 'find', model: 'Stock' },
          filter: { id: { _eq: sale?.stock?.id } },
        })
        appraisal.deal.lead.deals = deals
        appraisal.deal.lead.sale.auto = auto?.[0]
        appraisal.deal.lead.sale.stock = stock?.[0]
      }
    }

    if (appraisal?.deal?.auto) {
      appraisal.deal.auto.closingReason = this.setClosingReason(appraisal)
    }
    appraisal.disabled = this.disabledDeal
    return appraisal
  }

  @Debounce(1000)
  async setDetails () {
    const { metadataCollection, appraisal } = this

    const appraisalObj = await this.setAppraisalValues(appraisal)

    this.metadata = {
      data: appraisalObj,
      metadata: metadataCollection,
    }

    this.showDetail = (Boolean(appraisal.id) || Boolean(appraisal?.deal?.auto))
  }

  setClosingReason (appraisal) {
    if (appraisal.deal.auto && appraisal?.closingReason) {
      return appraisal?.closingReason
    }

    if (!appraisal?.id || this.disabledDeal) {
      // solo para mostrarlo momentaneamente en la creacion en el ala
      return plainToInstance(ClosingReason, {})
    }

    return null
  }

  @Watch('kmsAndMaintenance', { immediate: true })
  async onChangeMaintenance (val) {
    if (val?.maintenance?.length) {
      const sortMaintenance = deepCopy(val?.maintenance).sort(
        (a, b) => b.mileage - a.mileage
      )

      if (sortMaintenance.length) {
        await this.findPrepaidMaintenanceType(sortMaintenance[0].mileage)
      }
    } else if (val?.kms) {
      await this.findPrepaidMaintenanceType(val?.kms, 'kms')
    }
  }

  get kmsAndMaintenance () {
    const { formData } = this

    return { kms: formData?.kms, maintenance: formData?.maintenance }
  }

  get blockCav () {
    const { appraisal } = this

    return appraisal?.status?.isReadingFile || appraisal?.status?.isValidCav
  }

  @Debounce(500)
  async findPrepaidMaintenanceType (mileage, type = 'maintenance') {
    const { maxPrepaidMaintenances } = this

    if (mileage >= maxPrepaidMaintenances) return
    let items
    if (type === 'maintenance') {
      items = await this.fetchData({
        query: { name: 'find', model: 'MaintenanceType' },
        filter: { mileage: { _gt: mileage, _lte: maxPrepaidMaintenances } },
      })
    } else {
      items = await this.fetchData({
        query: { name: 'find', model: 'MaintenanceType' },
        filter: { mileage: { _lte: maxPrepaidMaintenances } },
      })
    }

    this.fields.prepaidMaintenance.items = items

    if (this.formData.prepaidMaintenance) {
      this.formData.prepaidMaintenance = items.filter(item =>
        this.formData.prepaidMaintenance.some(
          maintenance => maintenance.id === item.id
        )
      )
    }
  }

  @Watch('formData.kms', { immediate: true })
  async onChangeKms (km) {
    if (km) {
      await this.findMaintenanceType(km)
    }
  }

  @Debounce(500)
  async findMaintenanceType (km) {
    const {
      formData: { maintenance },
    } = this

    const max = maintenance?.length
      ? maintenance.reduce((prev, current) => {
        return prev.mileage > current.mileage ? prev : current
      })
      : null

    const search = max?.mileage > Number(km) ? max?.mileage : Number(km)

    const items = await this.fetchData({
      query: { name: 'find', model: 'MaintenanceType' },
      filter: { mileage: { _lte: search + 20000 } },
    })
    this.fields.maintenance.items = items
    this.fields.maintenance.items.unshift({
      id: undefined,
      description: 'Sin registro',
    })
    if (this.formData.maintenance) {
      this.formData.maintenance = items.filter(item =>
        this.formData.maintenance.some(
          maintenance => maintenance.id === item.id
        )
      )
    }
  }

  @Watch('formData.price', { immediate: true })
  onPriceChange (val) {
    const { appraisalResponseAmount } = this

    if (!isValidNumber(val)) {
      this.expectedPriceRule = []
      return
    }
    if (appraisalResponseAmount) {
      this.expectedPriceRule = [
        v =>
          Number(v) >= appraisalResponseAmount ||
          `No puede ingresar un valor menor a la oferta de compra ${fixPrice(
            appraisalResponseAmount
          )}`,
      ]
    }
    this.formData.agreementPrice = null
    if (this.$refs.form) {
      this.$refs.form.resetValidation()
    }
  }

  @Watch('formData.priceConsignment', { immediate: true })
  onPriceConsignmentChange (val) {
    const {
      appraisalResponseAmountConsignment,
      displayConsignment,
      formData: { price },
    } = this

    if (!displayConsignment || !isValidNumber(val)) return

    if (Number(price) >= Number(val)) {
      this.errorMessageConsignment =
        'El precio de consignación no puede ser menor al precio de compra'
    } else {
      this.errorMessageConsignment = ''
    }

    if (appraisalResponseAmountConsignment) {
      this.expectedPriceRuleConsignment = [
        v =>
          Number(v) >= appraisalResponseAmountConsignment ||
          `No puede ingresar un valor menor a la oferta de consignación ${fixPrice(
            appraisalResponseAmountConsignment
          )}`,
      ]
    }
    this.formData.agreementPrice = null
    if (this.$refs.form) {
      this.$refs.form.resetValidation()
    }
  }

  @Watch('formData.agreementPrice', { immediate: true })
  onAgreementPriceChange (val) {
    const { appraisalResponseAmount, appraisalResponseAmountConsignment } =
      this

    if (!isValidNumber(val)) return

    const maxAmount = Math.max(
      appraisalResponseAmount || 0,
      appraisalResponseAmountConsignment || 0
    )
    const dealType =
      maxAmount === appraisalResponseAmount ? 'compra' : 'consignación'

    if (val !== null && val !== '' && val !== undefined) {
      this.expectedAgreementPriceRule = [
        v =>
          Number(v) <= maxAmount ||
          `Precio máximo autorizado es de ${fixPrice(
            maxAmount
          )} para ${dealType}`,
        v =>
          Number(v) > maxAmount / 10 ||
          `Precio menor al 10% del precio autorizado de (${dealType}) ${fixPrice(
            maxAmount
          )}`,
      ]
      this.expectedPriceRule = []
    } else {
      this.expectedAgreementPriceRule = []
    }

    this.formData.price = null
    if (this.$refs.form) {
      this.$refs.form.resetValidation()
    }

    const client = this.appraisal.deal.lead.client
    if (!client?.uid) {
      this.formData.client = client
      this.uidRequired = true
    }
  }

  @Watch('formData.agreementPriceConsignment', { immediate: true })
  onAgreementPriceConsignmentChange (val) {
    const { appraisalResponseAmount } = this

    if (!isValidNumber(val)) return
    if (val !== null && val !== '' && val !== undefined) {
      this.expectedAgreementPriceRuleConsignment = [
        v =>
          Number(v) <= appraisalResponseAmount ||
          `No puede ingresar un valor mayor al tasado ${fixPrice(
            appraisalResponseAmount
          )}`,
        v =>
          Number(v) > appraisalResponseAmount / 10 ||
          `Precio menor al 10% del precio autorizado ${fixPrice(
            appraisalResponseAmount
          )}`,
      ]
      this.expectedPriceRule = []
    } else {
      this.expectedAgreementPriceRuleConsignment = []
    }
    this.formData.price = null
    if (this.$refs.form) {
      this.$refs.form.resetValidation()
    }
    const client = this.appraisal.deal.lead.client
    if (!client?.uid) {
      this.formData.client = client
      this.uidRequired = true
    }
  }

  @Debounce(500)
  async searchAuto ({ input }) {
    if (!input?.length || input?.length < 2) return null

    const { metadataCollection } = this

    const { fields } = metadataCollection as Form
    const query = updateNestedObject(
      fields.auto.computed.queries.items.where,
      '_eq',
      input
    )
    const items = await this.fetchData({
      query: { name: 'find', model: 'Auto' },
      filter: { ...query },
    })

    if (items?.length) {
      this.fields.auto.items = items
    }
  }

  get change () {
    const { formData, appraisal } = this

    return JSON.stringify(formData) + JSON.stringify(appraisal)
  }

  @Watch('formData.auto', { immediate: true, deep: true })
  async onAutoChange (val) {
    const maintenances = val?.maintenances
      ?.filter(maintenance => maintenance?.status?.isDone)
      .map(maintenance => maintenance?.type)
    if (maintenances?.length) {
      this.formData.maintenance = maintenances
    }

    if (this.formData.maintenance?.length) {
      const max = this.formData.maintenance.reduce((prev, current) => {
        return prev.mileage > current.mileage ? prev : current
      })

      const search =
        max?.mileage > Number(this.formData.kms)
          ? max?.mileage
          : Number(this.formData.kms)

      this.fields.maintenance.items = await this.fetchData({
        query: { name: 'find', model: 'MaintenanceType' },
        filter: { mileage: { _lte: search + 20000 } },
      })
      this.fields.maintenance.items.unshift({
        id: undefined,
        description: 'Sin registro',
      })
    }
    const prepaidMaintenance = val?.maintenances
      ?.filter(maintenance => maintenance?.status?.isPrepaid)
      .map(maintenance => maintenance?.type)
    if (prepaidMaintenance?.length) {
      this.formData.prepaidMaintenance = prepaidMaintenance
    }

    if (!val?.closingReason) {
    await this.setDetails()
    }
  }

  loadingFile (flag) {
    this.isUploadingFile = flag
  }

  cancelAppraisal () {
    const { formData, appraisal } = this

    this.close([...formData.photos, ...formData.document, ...formData.cav])
    this.formData.photos = formData.photos?.filter(
      photo => photo.idFileProcess
    )
    this.formData.cav = formData.cav?.filter(cav => cav.idFileProcess)
    this.formData.document = formData.document?.filter(
      document => document.idFileProcess
    )

    if (!appraisal?.id) {
      this.clearAuto()
    }
  }

  @Watch('formData.document', { immediate: true }) onBuyChange (val) {
    this.formData.linkLegalReport =
      val?.[0]?.sourceLink || val?.[0]?.file?.sourceLink
  }

  get displayCav () {
    const {
      expectedAgreementPriceRule,
      formData: { agreementPrice },
    } = this

    return (
      expectedAgreementPriceRule?.[0]?.(agreementPrice) === true &&
      expectedAgreementPriceRule[1](agreementPrice) === true
    )
  }

  get stock () {
    const { appraisal } = this

    return {
      auto: appraisal?.deal?.auto,
    }
  }

  goToButton () {
    const { stock } = this

    this.openPortal('https://autos.mercadolibre.cl/marca/modelo', stock, false)
  }

  async openAppraisalLink () {
    const { stock, formData } = this

    if (formData?.link) {
      this.openLink(formData.link)
      return
    }

    await this.buildLinkChileAutos(stock.auto)
  }

  async goPerson () {
    const { backup, formData } = this

    if (!backup) {
      this.setBackup({ appraisalForm: formData })
    } else {
      const back = {
        ...formData,
        appraisalForm: backup?.appraisalForm,
        auto: backup?.auto,
        financialForm: backup?.financialForm,
      }
      this.setBackup(back)
    }
    const idPerson = this.formData?.client?.id
    await this.$router.push({
      name: 'generic-person-nested',
      params: { model: 'Lead', uid: null, id: idPerson },
    })
  }

  get displayAutoResume () {
    const { appraisal } = this

    const status = ['expired', 'not_match', 'not_qualify']

    return (
      appraisal?.id &&
      !status.includes(appraisal?.deal?.closingReason?.name) &&
      !status?.includes(appraisal?.status?.name)
    )
  }

  get maxMileageConfig () {
    const { maxMileage, maxMileageConsignment } = this

    if (!maxMileage && !maxMileageConsignment) return []
    const max = Math.max(maxMileage, maxMileageConsignment || 0)
    return [
      v => v <= max || `No se permiten vehículos con más de ${max} kms.`,
    ]
  }

  get disabledConsignment () {
    const {
      formData: { price, kms },
      maxMileage,
    } = this

    if (kms > maxMileage) return false

    return kms <= maxMileage && !price
  }

  get displayPurchase () {
    const {
      formData: { kms },
      maxMileage,
    } = this

    const hidden = kms <= maxMileage

    if (hidden) {
      this.formData.price = null
    }

    return hidden
  }

  get disabledEditAuto () {
    const { appraisal } = this

    return (
      !appraisal?.closingReason?.id &&
      appraisal?.closingReason?.isExpired &&
      appraisal?.closingReason?.notQualify
    )
  }

  cleanFields () {
    this.formData.price = null
    this.formData.priceConsignment = null
  }

  @Watch('formData.maintenance', { immediate: true, deep: true })
  onMaintenanceChange (val) {
    const maintenanceWithoutId = val?.some(maintenance => !maintenance?.id)
    const maintenanceWithId = val?.some(maintenance => maintenance?.id)
    if (maintenanceWithoutId && maintenanceWithId) {
      this.formData.maintenance = val.filter(maintenance => maintenance?.id)
    }
  }
  }
