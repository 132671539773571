
  import UpdatePwa from '@/components/updatePwa.vue'
  import GlobalNotification from '@/components/global/GlobalNotification.vue'
  import { Component, Vue, Watch } from 'vue-property-decorator'
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import NetworkDetector from '@/components/NetworkDetector.vue'
  import { Schedule } from '@/entities/persons'
  import { Debounce } from '@/utils/decorators'
  import { UserData } from '@/store/user/state'
  import html2canvas from 'html2canvas'
  import { Query } from '@/entities/public/Resource/interfaces'
  import { formFilter, insertInput, updateInput } from '@/graphql/generated-types'
  import { File as FileLoad } from '@/entities/files'
  import { Role } from '@/store/user/models'

@Component({
  components: { UpdatePwa, GlobalNotification, NetworkDetector },
  methods: {
    ...mapActions('app', ['setMobile', 'setIsResize']),
    ...mapMutations('app', ['setSystem', 'setSchedule']),
    ...mapActions('resources/form', ['fetchData', 'pushData', 'uploadImage', 'deleteFile']),
    ...mapActions('user', ['sendHeartbeat']),
    ...mapActions('notification', ['sendNotificationPersonalized', 'sendNotificationBroadcast', 'sendNotificationRol', 'subscribeToNotifications']),
  },
  computed: {
    ...mapGetters('user', ['user', 'roles']),
  },
})
  export default class App extends Vue {
  setMobile!: (payload: boolean) => void
  setIsResize!: (payload: number) => void
  setSystem!: (payload: string) => void
  setSchedule!: (payload: Array<Schedule>) => void
  uploadImage!: (payload: { file: File[], idFileType: number, idProcess: number }) => Promise<Array<FileLoad>>
  sendHeartbeat!: ({ id, metadata }: { id: any, metadata: any }) => Promise<void>
  sendNotificationPersonalized!: (payload: { userId: number, message: any }) => Promise<void>
  sendNotificationBroadcast!: (payload: { message: any }) => Promise<void>
  sendNotificationRol!: (payload: { rol: string, message: any }) => Promise<void>
  subscribeToNotifications!: (payload) => void

  user!: UserData
  roles!: Array<Role>
  idProcess: number | null = null
  fieldFile = null
  heartbeatInterval: number | null = null

  fetchData!: (payload: {
    query: Query
    filter?: formFilter
    offset?: number
    limit?: number
    force?: boolean
    distinct?: Array<string>
  }) => Promise<any>;

  pushData!: (payload: {
    model: string
    fields?: insertInput | updateInput
  }) => Promise<any>;

  deleteFile!: (
    payload: Array<{ id: number, idFileProcess: number }>
  ) => Promise<void>;

  async mounted () {
    await this.getSystem()
  }

  @Debounce()
  async getSystem () {
    if (localStorage.getItem('apollo-token')) {
      const system = (await this.fetchData({
        query: { name: 'find', model: 'Person' },
        filter: { type: { name: { _eq: 'system' } } },
      }))[0]
      const process = await this.fetchData({
        query: { name: 'find', model: 'Process' },
        filter: { table_name: { _eq: 'user' } },
      })
      const fileInfo = await this.fetchData({
        query: { name: 'find', model: 'FileParameter' },
        filter: { process: { table_name: { _eq: 'user' } } },
      })

      this.fieldFile = fileInfo.find(f => f.name === 'last_seen')

      this.idProcess = process[0]?.id
      this.setSystem(system?.alias)
    }
  }

  @Watch('$vuetify.breakpoint', { immediate: true, deep: true })
  onBreakpointChange (val) {
    this.setMobile(val.width < 875)
  }

  @Watch('$vuetify.breakpoint.width', { immediate: true, deep: true })
  onWidthChange (val) {
    this.setIsResize(val)
  }

  @Watch('$vuetify.breakpoint.height', { immediate: true, deep: true })
  onHeightChange (val) {
    this.setIsResize(val)
  }

  get changeUser () {
    const { user, fieldFile, idProcess } = this

    return { user, fieldFile, idProcess }
  }

  @Watch('changeUser', { immediate: true, deep: true })
  async onUserChangeApp (val) {
    if (!val?.user?.id || !val?.fieldFile?.fileType?.id || !val?.idProcess) return
    this.subscribeToNotifications({ userId: val.id, roles: this.roles.map(r => r.slug) })

    if (this.heartbeatInterval) {
      window.clearInterval(this.heartbeatInterval)
      this.heartbeatInterval = null
    }

    this.heartbeatInterval = window.setInterval(async () => {
      const respFileProcess = await this.uploadImageMetadata(val)
      const path = this.$router.currentRoute.path
      await this.sendHeartbeat({ id: val.user.id, metadata: { file: respFileProcess, path } })
    }, 30000)
  }

  beforeDestroy () {
    // Limpiar temporizadores
    if (this.heartbeatInterval) {
      window.clearInterval(this.heartbeatInterval)
    }
  }

  async uploadImageMetadata (val) {
    if (this.$route.path.includes('financer')) {
      return ''
    }

    const files = await this.fetchData({
      query: { name: 'find', model: 'FileProcess', order: { id: 'asc' } },
      filter: {
        _and: [{ id_process_record: { _eq: val.user.id } }, { id_file_parameter: { _eq: val.fieldFile.id } }],
      },
      force: true,
    })

    if (files.length > 4) {
      const filesToDelete = [{
        id: files?.[0]?.file.id,
        idFileProcess: files?.[0]?.id,
      }]

      await this.deleteFile(filesToDelete)
    }

    const screenShot = await this.screenShot(val)

    const resp = await this.uploadImage({
      file: [screenShot],
      idFileType: val.fieldFile.fileType.id,
      idProcess: val.idProcess,
    })

    if (!resp?.length) return files?.[files.length - 1]

    const fileExist = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_file_parameter: { _eq: val.fieldFile.id } },
          { id_process_record: { _eq: val.user.id } },
          { id_file: { _eq: resp[0].id } },
        ],
      },
      force: true,
    })

    if (fileExist.length > 0) return fileExist[0]

    return this.pushData({
      model: 'FileProcess',
      fields: {
        id_file: resp[0].id,
        id_file_parameter: val.fieldFile.id,
        id_process_record: val.user.id,
      },
    })
  }

  async screenShot (val) {
    const canvas = await html2canvas(document.body)
    return this.base64ToFile(canvas.toDataURL('image/png'), `screenshot${val.id}.png`)
  }

  base64ToFile (base64: string, filename: string): File {
    const arr = base64.split(',')
    const mime = arr[0].match(/:(.*?);/)?.[1] || ''
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, { type: mime })
  }
  }
