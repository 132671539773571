
  import { Component, Watch } from 'vue-property-decorator'
  import { GForm } from '@/components/forms/GForm'
  import { plainToInstance } from 'class-transformer'
  import { Auto } from '@/entities/public'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import { Form } from '@/entities/public/Resource/metadata'
  import { isValidNumber, stringifySafe, updateNestedObject } from '@/utils/general'
  import { Debounce } from '@/utils/decorators'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import dayjs from 'dayjs'
  import _ from 'lodash'
  import { ComponentValue, VersionYear } from '@/entities/vehicle'
  import { mapActions } from 'vuex'
  import InspectionSummary from '@/components/forms/inspection/InspectionSummary.vue'

@Component({
  components: { InspectionSummary, GAlert, BaseForm },
  methods: {
    ...mapActions('resources/form', ['findPpu', 'findVersionSuggestionByPPU']),
  },
  computed: {},
})
  export default class AutoForm extends GForm {
  autoInfo = {
    brand: null,
    models: null,
    versions: null,
    year: null,
  }

  autoInfoAux: Auto | null = null

  autos = []
  autoDetail = null
  showInspectionDetail = false
  inspection = null
  disabledSend = false
  loadingForm = false
  appraisal = null
  autoIsNotMatch = false
  existVersion = false
  existVersionFound = null
  loading = false
  previousAuto = {
    open: false,
    title: 'Este vehículo esta registrado en nuestra ',
    subtitle: '',
  }

  ppuMandatory = false
  validPpuLetter = null
  maxYear = null
  auto: Auto = plainToInstance(Auto, {})
  temporalAuto: Auto = plainToInstance(Auto, {})
  disabledFields = false
  displayGeneration = false
  isDeprecatedSelected = false
  effectiveYear = null
  suggestedVersion = null
  fuels: ComponentValue[] = []
  transmissions: ComponentValue[] = []
  traction: ComponentValue[] = []
  bodywork: ComponentValue[] = []
  addSuggestion = false
  message = ''
  messageInfo = ''
  messageNotMatch = ''
  autoAlert = {
    open: false,
    subtitle: '',
    title: '',
  }

  showDetail = false
  title = ''
  formData = {
    ppu: null,
    brand: null,
    model: null,
    version: null,
    year: null,
    generation: null,
    newModel: null,
    newVersion: null,
  }

  form = {
    fuel: null,
    transmission: null,
    traction: null,
    cylinder: null,
    bodywork: null,
  }

  fields = {
    brand: {
      properties: {},
      items: [],
      rules: [],
    },
    model: {
      properties: {},
      items: [],
      rules: [],
    },
    ppu: {
      properties: {},
      items: [],
      rules: [],
    },
    version: {
      properties: {},
      items: [],
      rules: [],
    },
    generation: {
      properties: {},
      items: [],
      rules: [],
    },
    year: {
      properties: {},
      items: [],
      rules: [],
    },
  }

  newModelRules = []
  newVersionRules = []

  findPpu!: (ppu: string) => Promise<any>
  findVersionSuggestionByPPU!: (ppu: string) => Promise<string>

  metadata = {}
  metadataCollection = {}

  async mounted () {
    this.loadingForm = true
    await this.setMetadata()
    const { uid, id, title, metadataCollection } = this
    const autoId = id || uid

    if (isValidNumber(autoId)) {
      await this.getAutoInfo(autoId)
    }

    this.setTheBackup()

    if (!this.isBreadCrumbPresent(title)) {
      this.setFormCrumbs(metadataCollection, title, Boolean(autoId))
    }

    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'auto' } },
    })

    this.ppuMandatory = process?.[0]?.config?.ppuMandatory
    this.validPpuLetter = process?.[0]?.config?.validPpuLetter
    this.maxYear = process?.[0]?.config?.maxYear

    this.setLoadingFalse()
  }

  @Debounce(500)
  setLoadingFalse (): void {
    this.loadingForm = false
  }

  async getAutoInfo (id) {
    const auto = await this.fetchData({
      query: { name: 'fetch', model: 'Auto', params: { id } },
      force: true,
    })
    let isNotQualify = false

    if (this.uid) {
      this.appraisal = await this.fetchData({
        query: { name: 'fetch', model: 'Appraisal', params: { id: this.uid } },
        force: true,
      })
    }

    if (this.appraisal) {
      const { deal: { closingReason } } = this.appraisal

      isNotQualify = closingReason?.name === 'not_qualify'
    }

    if (auto && !isNotQualify) {
      this.formData.year = auto.version.year.id
      this.formData.brand = auto.version.version.model.brand
      this.formData.model = auto.version.version.model
      this.formData.version = auto.version
      this.formData.ppu = auto?.registrationPlate
      this.formData.generation = auto.generation
      this.auto = auto
    }
  }

  async setMetadata () {
    const { metadata } = this.getForm('Auto', 'auto')
    const { fields, form } = metadata as Form

    this.title = form.title
    this.metadataCollection = metadata
    this.fields.brand.properties = fields.brand.properties
    this.fields.model.properties = fields.model.properties
    this.fields.version.properties = fields.version.properties
    this.fields.year.properties = fields.year.properties
    this.fields.ppu.properties = fields.ppu.properties
    this.fields.generation.properties = fields.generation.properties

    this.fields.year.items = await this.fetchData({
      query: { name: 'find', model: 'Year' },
    })
  }

  setTheBackup () {
    const { backup } = this
    if (backup && 'auto' in backup && backup.auto) {
      this.formData.year = backup.auto.version.year
      this.formData.brand = backup.auto.version.version.model.brand
      this.formData.model = backup.auto.version.version.model
      this.formData.version = backup.auto.version
      this.formData.ppu = backup.auto.registrationPlate
    }
  }

  get disabledPlate () {
    const { auto } = this

    return Boolean(auto?.registrationPlate) && auto.registrationPlate.length === 6 && Boolean(auto.id)
  }

  get isOtherModelSelected () {
    return this.formData.model && this.formData.model.id === undefined
  }

  get isOtherVersionSelected () {
    return this.formData.version && this.formData.version?.version?.name === 'Otra' && this.formData.version.id === undefined && !this.addSuggestion
  }

  async validateNewModel () {
    const newModelProcessed = this.processModelName(this.formData.newModel)
    const existingModels = this.fields.model.items.map(item => this.processModelName(item.name))
    if (existingModels.includes(newModelProcessed)) {
      // El modelo ya existe
      this.newModelRules = [() => `El modelo '${this.formData.newModel}' ya existe en la lista`]
    } else {
      // No hay error
      this.newModelRules = []
    }

    if (this.formData.brand) {
      let items = await this.fetchData({
        query: {
          name: 'find',
          model: 'VersionYear',
          order: {
            version: { name: 'asc' },
          },
        },
        filter: {
          version: { model: { id_brand: { _eq: this.formData.brand.id } } },
        },
        force: true,
      })
      items = _.uniqBy(items, item => item.version.name)
      this.fields.version.items = items
      this.fields.version.items.push({ id: undefined, version: { name: 'Otro' } })
    }
  }

  async validateNewVersion () {
    await this.onNewVersionChange(this.formData.newVersion)
    const newVersionProcessed = this.processModelName(this.formData.newVersion)
    const existingVersions = this.fields.version.items.map(item => this.processModelName(item.version.name))
    if (existingVersions.includes(newVersionProcessed)) {
      this.newVersionRules = [() => `La versión '${this.formData.newVersion}' ya existe en la lista`]
    } else {
      this.newVersionRules = []
    }
  }

  async onNewVersionChange (newVersion) {
    if (!newVersion?.length) {
      this.existVersion = false
      return
    }
    this.loadingForm = true

    const existVersion = await this.fetchData({
      query: { name: 'find', model: 'VersionYear', order: { id: 'desc' } },
      filter: {
        _and: [
          {
            version: {
              _and: [
                { name: { _eq: `${newVersion}` } },
                { model: { id_brand: { _eq: this.formData.brand.id } } },
              ],
            },
          },
        ],
      },
      limit: 1,
      force: true,
    })

    if (existVersion?.id) {
      this.existVersionFound = await this.fetchData({
        query: { name: 'fetch', model: 'VersionYear', params: { id: existVersion?.id } },
        force: true,
      })
    }

    this.existVersion = Boolean(existVersion?.id)
    this.loadingForm = false
  }

  processModelName (name) {
    return name?.replace(/\s|-/g, '').toLowerCase().trim()
  }

  displayDetail () {
    const { auto, metadataCollection, formData } = this

    if (auto?.version?.year) {
      auto.version.year = formData.year
    }

    if (!auto?.version?.id) {
      auto.generation = null
    }
    this.metadata = {
      data: auto,
      metadata: metadataCollection,
    }

    this.showDetail = Boolean(auto?.version?.id) && Boolean(auto?.version?.version?.id)
  }

  saveBackup () {
    const { backup, auto, formData: { ppu } } = this

    if (backup) {
      auto.registrationPlate = ppu
      backup.auto = auto
      this.setBackup(backup)
    } else {
      this.setBackup({ auto })
    }
  }

  async send () {
    const {
      ppuErrorMessage,
      newModelRules,
      newVersionRules,
      existVersion,
      effectiveYear,
      formData: { year, version },
    } = this

    const isNewVersionYear = year?.id !== version?.year?.id
    const error = ppuErrorMessage.length || newModelRules.length || newVersionRules.length
    if (!this.$refs.form.validate() || error) {
      await this.setFieldError()
      return
    }
    this.loadingForm = true

    let newModel
    // Crear modelo si no existe
    if (this.isOtherModelSelected && this.formData.newModel) {
      newModel = await this.pushData({
        model: 'Model',
        fields: {
          name: this.formData.newModel.toUpperCase(),
          id_brand: this.formData.brand.id,
        },
      })
    }

    // Crear version year si no existe
    if (this.formData.newVersion?.length && !existVersion) {
      const cylinder = (await this.fetchData({
        query: { name: 'find', model: 'Component' },
        filter: {
          name: { _eq: 'Cilindrada' },
        },
      }))[0]
      const attrs = [{
        id_component: cylinder.id,
        value: this.form.cylinder?.toUpperCase(),
        validated: false,
      }].filter(attr => attr.value)
      const version = await this.pushData({
        model: 'Version',
        fields: {
          name: this.formData.newVersion.toUpperCase(),
          id_model: this.formData?.model?.id || newModel?.id,
          years: {
            data: [{
              id_year: this.formData.year.id,
              attributes: {
                data: [
                  ...attrs,
                  {
                    id_component: this.form.fuel.component.id,
                    id_component_value: this.form.fuel.id,
                    validated: false,
                  },
                  {
                    id_component: this.form.transmission.component.id,
                    id_component_value: this.form.transmission.id,
                    validated: false,
                  },
                  {
                    id_component: this.form.traction.component.id,
                    id_component_value: this.form.traction.id,
                    validated: false,
                  },
                  {
                    id_component: this.form.bodywork.component.id,
                    id_component_value: this.form.bodywork.id,
                    validated: false,
                  },
                ],
              },
            },
            ],
          },
        },
      })
      const versionYear = (await this.fetchData({
        query: { name: 'find', model: 'VersionYear' },
        filter: {
          id_version: { _eq: version.id },
          id_year: { _eq: this.formData.year.id },
        },
        force: true,
      }))[0]

      this.auto.version = await this.fetchData({
        query: { name: 'fetch', model: 'VersionYear', params: { id: versionYear.id } },
        force: true,
      })
      this.auto.generation = null
      this.saveBackup()
    } else if (existVersion || effectiveYear?.id || isNewVersionYear) {
      await this.createVersionYear()
    } else {
      await this.setAutoNewValues(this.formData)
    }

    await this.checkAuto()
    await this.close()
  }

  async checkAuto () {
    const { auto, autoDetail } = this

    if (auto?.version?.id !== autoDetail?.version?.id && autoDetail?.version?.id && this.auto?.id) {
      const notMatchStatus = await this.fetchData({
        query: { name: 'find', model: 'ProcessStatus' },
        filter: {
          _and: [
            { process: { table_name: { _eq: 'auto' } } },
            { status: { name: { _eq: 'not_match' } } },
          ],
        },
      })
      await this.pushData({
        model: 'Auto',
        fields: {
          id: this.auto?.id,
          id_process_status: notMatchStatus?.[0]?.id,
        },
      })
      this.auto.id = null
    }
    this.saveBackup()
  }

  buildAttributes (attributes) {
    if (!Array.isArray(attributes)) {
      throw new Error('Invalid attributes: Expected an array.')
    }

    return attributes.map(attr => {
      const hasValue = attr?.value !== undefined && attr?.value !== null
      const attributeData = {
        id_component: attr?.component?.id,
        validated: false, // Default value, can be adjusted
      }

      if (hasValue) {
        // If value exists, include it
        attributeData.value = attr.value
      } else if (attr.componentValue?.id) {
        // Include componentValue ID if available
        attributeData.id_component_value = attr.componentValue.id
      }

      return attributeData
    })
  }

  async createVersionYear () {
    const { year, generation: formGeneration } = this.formData

    if (!this.existVersionFound) {
      this.formData.version = await this.fetchData({
        query: { name: 'fetch', model: 'VersionYear', params: { id: this.formData.version.id } },
        force: true,
      })
    }

    const existVersion = (await this.fetchData({
      query: { name: 'find', model: 'VersionYear' },
      filter: {
        _and: [
          { id_version: { _eq: this.formData.version.version.id } },
          { id_year: { _eq: year.id } },
        ],
      },
      force: true,
    }))[0]

    if (existVersion?.id) {
      this.formData.version = await this.fetchData({
        query: { name: 'fetch', model: 'VersionYear', params: { id: this.formData.version.id } },
        force: true,
      })

      this.saveBackupNewAuto(this.formData.version)
      return
    }

    const { version, attributes, generations } = this.existVersionFound || this.formData?.version

    const findGeneration = generations?.find(item => item.id === formGeneration?.id)

    const generation = findGeneration || (generations?.length ? generations[generations?.length - 1] : null)
    let generationFetched
    if (generation?.id) {
      generationFetched = await this.fetchData({
        query: { name: 'fetch', model: 'Generation', params: { id: generation?.id } },
      })
    }
    const customAttributes = generationFetched?.attributes || attributes

    const processedAttributes = this.buildAttributes(customAttributes)

    const fields = {
      id_version: version?.id,
      id_year: year?.id,
      attributes: {
        data: processedAttributes,
      },
    }

    const newVersion = await this.pushData({
      model: 'VersionYear',
      fields,
    })

    this.saveBackupNewAuto(newVersion)
  }

  saveBackupNewAuto (version) {
    const { backup, formData: { ppu } } = this

    this.auto.version = version
    if (backup) {
      this.auto.registrationPlate = ppu
      backup.auto = this.auto
      this.setBackup(backup)
    } else {
      this.setBackup({ auto: this.auto })
    }
  }

  async setAutoNewValues (formData) {
    if (
      this.auto.generation?.id !== formData.generation?.id &&
      formData.generation?.id
    ) {
      this.auto.generation = formData.generation
      this.auto.version = formData.version
      this.auto.id = null
    } else if (this.auto.version?.id !== formData.version?.id) {
      this.auto.version = formData.version
      this.auto.generation = formData.generation
      this.auto.id = null
    }

    if (this.uid) {
      const appraisal = await this.fetchData({
        query: { name: 'fetch', model: 'Appraisal', params: { id: this.uid } },
        force: true,
      })

      if (appraisal?.deal?.closingReason?.id) {
        const statusAuto = await this.fetchData({
          query: { name: 'find', model: 'ProcessStatus' },
          filter: { _and: [{ process: { table_name: { _eq: 'auto' } } }, { status: { name: { _eq: 'new' } } }] },
        })
        const fields = {
          id: this.autos.find(auto => auto.status.isNew)?.id,
          id_process_status: statusAuto?.[0]?.id,
          id_version_year: formData?.version?.id,
          registration_plate: formData.ppu,
          id_generation: formData?.generation?.id,
        }

        if (fields?.id_generation) {
          delete fields.id_version_year
        }

        const auto = await this.pushData({
          model: 'Auto',
          fields,
        })
        if (this.backup) {
          auto.registrationPlate = formData.ppu
          this.backup.auto = auto
          this.setBackup(this.backup)
        } else {
          this.setBackup({ auto })
        }
      }
    } else {
      this.saveBackup()
    }
  }

  @Watch('formData.year', { immediate: true })
  async onYearChange (year) {
    if (year) {
      if (!this.autoInfo && !this.backup?.auto) {
        this.formData.brand = null
        this.formData.model = null
        this.formData.version = null
        this.formData.generation = null
      } else if (!this.backup?.auto) {
        this.fields.brand.items = [this.autoInfo?.brand]
        return
      }
      const { metadataCollection, formData } = this
      const { fields } = metadataCollection as Form
      if (fields) {
        const items = await this.fetchData({
          query: {
            name: 'find',
            model: 'Brand',
            order: {
              name: 'asc',
            },
          },
          force: true,
        })

        this.fields.brand.items = items

        if (formData.brand && !items.some(item => item.id === formData.brand?.id)) {
          formData.brand = null
        }
      }
    }
  }

  @Watch('formData.brand', { immediate: true })
  async onBrandChange (brand) {
    this.cleanAuto()
    if (!this.backup?.auto) {
      this.formData.model = null
      this.formData.version = null
      this.formData.generation = null
    }

    if (brand) {
      if (!this.autoInfo?.brand && !this.backup?.auto) {
        this.formData.model = null
        this.formData.version = null
        this.formData.generation = null
      } else if (!this.backup?.auto) {
        this.fields.model.items = this.autoInfo?.models
        // Other model option if is required
        // this.fields.model.items.push({ id: undefined, name: 'Otro' })
        if (this.autoInfo?.models?.length === 1) {
          this.formData.model = this.autoInfo.models[0]
        }
        return
      }

      const { metadataCollection } = this
      const { fields } = metadataCollection as Form
      if (fields) {
        const items = await this.fetchData({
          query: {
            name: 'find',
            model: 'Model',
            order: {
              name: 'asc',
            },
          },
          filter: { id_brand: { _eq: brand.id } },
          force: true,
        })
        this.fields.model.items = items
        // this.fields.model.items.push({ id: undefined, name: 'Otro' })
        if (this.formData.model && !items.some(item => item.id === this.formData.model?.id)) {
          this.formData.model = null
        }
      }
    }
  }

  @Watch('formData.model', { immediate: true })
  async onModelChange (model) {
    this.cleanAuto()
    if (!this.backup?.auto) {
      this.formData.version = null
      this.formData.generation = null
    }
    if (model?.id) {
      if (!this.autoInfo && !this.backup?.auto) {
        this.formData.version = null
      }
      const { metadataCollection, autoInfo } = this
      const { fields } = metadataCollection as Form
      if (fields) {
        const items = await this.fetchData({
          query: {
            name: 'find',
            model: 'VersionYear',
            order: {
              id_year: 'desc',
            },
          },
          filter: {
            version: { model: { _and: [{ id_brand: { _eq: this.formData.brand.id } }, { id: { _eq: model?.id } }] } },
          },
          force: true,
        })

        this.fields.version.items = this.sortVersionYear(items)
        const pos = this.fields.version.items.findIndex(item => item.version.id === this.autoInfo?.versions?.[0].id)

        if (pos !== -1) {
          this.fields.version.items[pos] = items.find(item => item.version.id === this.autoInfo?.versions?.[0].id && item.year.id === this.autoInfo?.year)
        }
        this.fields.version.items.push({ id: undefined, version: { name: 'Otra' } })
        if (!this.backup?.auto) {
          this.formData.version = items.find(item => item.version.id === autoInfo?.versions?.[0].id && item.year.id === autoInfo?.year)
          this.checkVersionDeprecated(this.formData.version)
        }
      }
    }
  }

  sortVersionYear (data) {
    // Hacemos una copia del array original para evitar mutaciones
    const dataCopy = [...data]

    // Paso 2: Crear un mapa para almacenar versiones únicas
    const uniqueVersionsMap = new Map()

    // Paso 3: Iterar sobre el array ordenado y llenar el mapa
    for (const item of dataCopy) {
      const versionName = item.version.name
      if (!uniqueVersionsMap.has(versionName)) {
        uniqueVersionsMap.set(versionName, item)
      }
    }

    // Paso 4: Extraer los valores del mapa
    const uniqueItems = Array.from(uniqueVersionsMap.values())

    // Paso 5: Ordenar los items por 'version.name' ascendentemente
    uniqueItems.sort((a, b) => a.version.name.localeCompare(b.version.name))

    return uniqueItems
  }

  cleanVersion () {
    this.cleanAuto()
    if (!this.backup?.auto) {
      this.auto.version = null
    }
  }

  cleanVersionSelectionData () {
    this.formData.newVersion = null
    this.formData.generation = null
    this.displayGeneration = false
    this.existVersion = false
    this.auto.version = null
    this.auto.generation = null
  }

  @Watch('formData.version', { immediate: true, deep: true })
  async onVersionChange (version, oldVersion) {
    if (!version) {
      this.cleanVersion()
      return
    }
    this.cleanVersionSelectionData()
    if (version?.id && oldVersion?.id !== version?.id) {
      this.cleanAuto()
      if (!this.backup?.auto) {
        this.formData.generation = null
      }
      const generation = await this.fetchData({
        query: { name: 'find', model: 'Generation' },
        filter: { id_version_year: { _eq: version.id } },
      })

      if (generation?.length > 1) {
        this.displayGeneration = true
        this.fields.generation.items = generation
      } else if (generation?.length === 1) {
        this.fields.generation.items = []
        this.displayGeneration = false
        this.auto.generation = generation?.[0]
        this.formData.generation = generation?.[0]
      } else {
        this.displayGeneration = false
        this.fields.generation.items = []
      }
    }
    this.auto.registrationPlate = this.formData.ppu
    this.auto.version = _.cloneDeep(version)
    this.checkVersionDeprecated(version)
    this.displayDetailWithDelay()
  }

  checkVersionDeprecated (version) {
    const { formData: { year } } = this
    const autos = this.autos.filter(auto => auto.status.isNotMatch)

    if (autos?.length && autos.find(auto => auto.version.version.id === version?.version?.id && auto?.version?.year?.id === year?.id)) {
      this.messageNotMatch = 'Ya fue reportado que los datos de este vehículo no coinciden con esta versión'
    } else {
      this.messageNotMatch = ''
    }
  }

  @Watch('formData.generation', { immediate: true, deep: true })
  async onGenerationChange (generation, oldGeneration) {
    if (generation?.id && oldGeneration?.id && oldGeneration?.id !== generation?.id) {
      this.auto.generation = null
      this.displayDetail()
    }
    if (generation) {
      this.auto.generation = generation
    }
    this.displayDetailWithDelay()
  }

  @Debounce(500)
  displayDetailWithDelay () {
    this.displayDetail()
  }

  @Watch('formData.ppu', { immediate: true })
  async onPpuChange (ppu) {
    if (ppu?.length === 6) {
      this.isDeprecatedSelected = false
      await this.searchAuto(ppu)
    }
  }

  async getAutos (query) {
    this.autos = await this.fetchData({
      query: { name: 'find', model: 'Auto', order: { id: 'desc' } },
      filter: { ...query },
      force: true,
      limit: 100,
    })

    if (this.autos.length) {
      this.appraisal = await this.fetchData({
        query: { name: 'find', model: 'Appraisal', order: { id: 'desc' } },
        filter: { deal: { id_auto: { _eq: this.autos[0]?.id } } },
        limit: 1,
        force: true,
      })
    }

    return this.autos[0]
  }

  initializeValues () {
    this.effectiveYear = null
    this.loading = true
    this.cleanAuto()
    this.message = ''
    if (!this.backup?.auto) {
      this.clearVehicleInfo()
    }
  }

  @Debounce(500)
  async searchAuto (input) {
    this.initializeValues()
    if (!input?.length || input?.length < 6) {
      this.loading = false
      return null
    }

    const { metadataCollection } = this

    const { fields } = metadataCollection as Form
    const query = updateNestedObject(fields.auto.computed.queries.items.where, '_eq', input)

    const auto = await this.getAutos(query)
    const { appraisal } = this

    if (this.autos.length) {
      this.autoIsNotMatch = auto?.status?.isNotMatch

      if (!auto?.status?.isNotMatch && !appraisal) {
        this.setAutoValues(auto, input)
      } else if (!appraisal) {
        await this.findPpuInDb(input)
        this.auto.registrationPlate = input
      } else {
        await this.checkAutoIsNotQualify(appraisal, input, auto)
      }
    } else {
      await this.findPpuInDb(input)
      this.auto.registrationPlate = input
      this.disabledSend = false
    }

    await this.getSuggestedPpu(input)
    this.loading = false
  }

  async getSuggestedPpu (ppu) {
    const version = (await this.findVersionSuggestionByPPU(ppu))?.[0]

    if (this.id) {
      this.autoInfoAux = await this.fetchData({
        query: { name: 'fetch', model: 'Auto', params: { id: this.id } },
        force: true,
      })
    }
    if (version) {
      this.suggestedVersion = version
    }
  }

  setAutoValues (auto, ppu) {
    if (auto?.id === this.id) return

    if (!auto.status.isSold && !auto.status.isDeprecated && !auto.status.isNew && !auto.status.isRetired) {
      this.message = `El vehículo se encuentra en estado ${auto.status.description}, no puedes crear una tasación con este vehículo`
    } else {
    this.temporalAuto = auto
    this.autoAlert = {
        open: true,
        title: `El vehículo con la patente: ${ppu} ya se encuentra registrado en nuestro sistema ¿desea continuar?`,
        subtitle: `${auto.alertName}`,
      }
      this.auto.registrationPlate = auto?.registrationPlate
    }
  }

  async checkAutoIsNotQualify (appraisal, ppu, auto) {
    const notQualify = appraisal?.deal?.closingReason?.name === 'not_qualify'
    const isSamePPu = appraisal?.deal?.auto?.registrationPlate === ppu
    const notMatch = auto?.status?.isNotMatch
    if (notQualify && isSamePPu) {
      this.message = `El vehículo "No califica" para este negocio`
      this.disabledSend = true
      return
    } else if (!notMatch) {
      this.setAutoValues(auto, ppu)
    } else {
      await this.findPpuInDb(ppu)
      this.auto.registrationPlate = ppu
    }
    this.disabledSend = false
  }

  async findPpuInDb (ppu) {
    this.messageInfo = ''
    const info = await this.findPpu(ppu)

    if (info?.auto?.year === -1) return

    if (!info?.auto?.year) {
      const { validPpuLetter } = this

      if (ppu?.length && validPpuLetter && !this.isValidLicensePlate(ppu)) {
        this.messageInfo = `Patente no figura en nuestro registro, ingresa la información manualmente`
      } else {
        this.setMessage(false, '')
      }
      return
    }

    this.autoInfo = info.auto

    const { auto: { brand, models, year, versions } } = info
    const { fields, maxYear } = this

    const currentYear = dayjs().year()

    if (maxYear && info.auto.year < currentYear - maxYear) {
      this.setMessage(true, `El vehículo ingresado tiene más de ${maxYear} años`)
      return
    } else {
      this.setMessage(false, '')
    }

    this.formData.year = fields.year.items.find(item => item.id === year)
    this.formData.brand = brand

    if (models?.length === 1) {
      this.formData.model = models[0]
      this.formData.version = plainToInstance(VersionYear, { version: versions[0] })
    } else {
      this.fields.model.items = models
    }
  }

  get disableBrand () {
    const { autoInfo } = this

    if (!autoInfo) {
      return false
    }

    const { brand } = autoInfo

    return Boolean(brand)
  }

  get disabledSendVersion () {
    const { auto, isOtherModelSelected, formData, isOtherVersionSelected, enableSend, existVersion } = this
    if (existVersion) {
      return false
    }

    if (isOtherModelSelected && !formData.newModel) {
      return true
    }
    if (isOtherVersionSelected && !formData.newVersion) {
      return true
    }
    return !auto.version?.id && !enableSend
  }

  get enableSend () {
    const { formData: { newVersion }, form: { traction, fuel, transmission } } = this

    return newVersion?.length && traction && fuel && transmission
  }

  get disableModel () {
    const { autoInfo } = this

    if (!autoInfo) {
      return false
    }

    const { models } = autoInfo

    return models?.length === 1
  }

  get disableVersion () {
    const { autoInfo } = this

    if (!autoInfo) {
      return false
    }

    const { versions } = autoInfo

    return versions?.length === 1
  }

  get disableYear () {
    const { autoInfo, loading } = this

    if (loading) {
      return true
    }

    if (!autoInfo) {
      return false
    }

    const { year } = autoInfo

    return Boolean(year)
  }

  get isPpuValid () {
    const { formData: { ppu } } = this

    return ppu?.length !== 6
  }

  get change () {
    const { formData } = this

    return stringifySafe([formData])
  }

  async setAuto (auto, flag = false) {
    this.auto = auto
    this.saveBackup()
    this.autoDetail = _.cloneDeep(auto)
    this.formData = {
      ppu: auto.registrationPlate,
      brand: auto.version.version.model.brand,
      model: auto.version.version.model,
      version: auto.version,
      year: auto.version.year,
      generation: auto.generation,
      newModel: null,
      newVersion: null,
    }

    this.autoAlert = {
      open: false,
      title: ``,
      subtitle: ``,
    }
    this.temporalAuto = null

    const appraisals = await this.fetchData({
      query: { name: 'find', model: 'Appraisal' },
      filter: { deal: { id_auto: { _eq: auto.id } } },
      force: true,
    })

    if (appraisals.length) {
      const appraisal = appraisals[appraisals.length - 1]
      const inspection = await this.fetchData({
        query: { name: 'find', model: 'Inspection', order: { id: 'desc' } },
        filter: {
          _and: [
            { id_appraisal: { _eq: appraisal.id } },
            { inspector_qualification: { _is_null: false } },
            { supervisor_qualification: { _is_null: false } },
          ],
        },
        force: true,
      })
      if (inspection?.length) {
        this.inspection = inspection[0]
        this.showInspectionDetail = true
      }
    } else if (flag) {
      this.previousAuto = {
        open: true,
        title: `El vehículo con la patente: ${auto.ppu} ya se encuentra registrado en nuestro sistema ¿desea continuar?`,
        subtitle: `${auto.alertName}`,
      }
    }
  }

  cleanAuto () {
    if (this.loadingForm) return
    if (!this?.backup?.auto && !this.id) {
      this.auto = plainToInstance(Auto, {})
    }
    this.temporalAuto = null
    this.displayDetail()
  }

  cleanForm () {
    this.isDeprecatedSelected = true
    this.previousAuto = {
      open: false,
      title: '',
      subtitle: '',
    }
    this.cleanAuto()
    this.formData = {
      ppu: null,
      brand: null,
      model: null,
      version: null,
      year: null,
      generation: null,
      newModel: null,
      newVersion: null,
    }
    this.autoAlert = {
      open: false,
      title: ``,
      subtitle: ``,
    }
  }

  clearVehicleInfo () {
    this.autoInfo = null
    this.formData.brand = null
    this.formData.model = null
    this.formData.version = null
    this.formData.year = null
    this.formData.generation = null
  }

  isValidLicensePlate (ppu) {
    if (!ppu || typeof ppu !== 'string') return null
    if (ppu.length < 6) return false

    const firstLetter = ppu[0].toUpperCase()

    const { validPpuLetter } = this

    return firstLetter >= validPpuLetter.toUpperCase()
  }

  setMessage (flag, message) {
    this.disabledFields = flag
    this.message = message
  }

  @Watch('showInspectionDetail', { immediate: true })
  onShowInspectionDetailChange (showInspectionDetail, oldShowInspectionDetail) {
    if (!showInspectionDetail && oldShowInspectionDetail) {
      this.close()
    }
  }

  @Watch('formData', { immediate: true, deep: true })
  async onFormDataChange (formData) {
    if (this.isDeprecatedSelected) return

    // Verificar si existe algún auto en el array que coincida completamente
    const autoMatch = this.autos.some(auto => {
      return (
        (!formData.generation || auto.generation?.id === formData.generation.id) &&
        (!formData.version || auto.version?.id === formData.version.id) &&
        (!formData.year || auto.version?.year?.id === formData.year.id) &&
        (!formData.model || auto.version?.version?.model?.id === formData.model.id) &&
        (!formData.brand || auto.version?.version?.model?.brand?.id === formData.brand.id)
      )
    })

    // Mensaje de error si no coincide ningún auto
    this.messageNotMatch = autoMatch
      ? 'Ya fue reportado que los datos de este vehículo no coinciden con esta versión'
      : ''

    if (autoMatch) {
      await this.getModels() // Opcional: Puedes actualizar los modelos si no hay coincidencias
    }

    // Si el auto está marcado como "deprecated", seleccionarlo automáticamente
    const deprecatedAuto = this.autos.find(auto => auto.status.isDeprecated)
    if (deprecatedAuto && this.auto?.id !== deprecatedAuto.id) {
      this.isDeprecatedSelected = true
      await this.setAuto(deprecatedAuto, true)
    }
  }

  @Watch('formData.newVersion', { immediate: true })
  async onDisplaySuggestedVersionChange (newVersion) {
    if (newVersion?.length && !this.transmissions.length) {
      this.transmissions = await this.fetchData({
        query: { name: 'find', model: 'ComponentValue' },
        filter: {
          component: {
            name: { _eq: 'Transmisión' },
          },
        },
      })

      this.traction = await this.fetchData({
        query: { name: 'find', model: 'ComponentValue' },
        filter: {
          _and: [
            { component: { name: { _eq: 'Tracción' } } },
            { value: { _neq: '4x2 y 4x4' } },
          ],
        },
      })

      this.bodywork = await this.fetchData({
        query: { name: 'find', model: 'ComponentValue' },
        filter: {
          component: {
            name: { _eq: 'Carrocería' },
          },
        },
      })

      this.fuels = await this.fetchData({
        query: { name: 'find', model: 'ComponentValue' },
        filter: {
          component: {
            name: { _eq: 'Combustible' },
          },
        },
      })
    }
  }

  @Watch('addSuggestion', { immediate: true })
  async onAddSuggestionChange (addSuggestion) {
    this.formData.newVersion = addSuggestion ? this.suggestedVersion : ''
  }

  @Watch('displaySuggestedVersion', { immediate: true })
  onDisplaySuggestedVersion (displaySuggestedVersion) {
    if (displaySuggestedVersion) {
      this.addSuggestion = true
      this.formData.newVersion = this.suggestedVersion
    } else {
      this.formData.newVersion = null
      this.addSuggestion = false
    }
  }

  async getModels () {
    if (!this.formData?.brand?.id || !this.formData?.year?.id) return
    const { metadataCollection } = this
    const { fields } = metadataCollection as Form
    if (fields) {
      const query = updateNestedObject(fields.model.computed.queries.items.where, '_eq',
        [
          { target: 3, value: this.formData.brand.id },
          { target: 2, value: this.formData.year.id },
        ])
      this.fields.model.items = await this.fetchData({
        query: {
          name: 'find',
          model: 'Model',
          order: {
            name: 'asc',
          },
        },
        filter: { ...query },
        force: true,
      })
    }
  }

  closeForm () {
    this.close()
  }

  get ppuErrorMessage () {
    const { messageNotMatch, message } = this

    if (message?.length) {
      return [message]
    }

    if (messageNotMatch?.length) {
      return [messageNotMatch]
    }

    return []
  }

  get displaySuggestedVersion () {
    const {
      suggestedVersion,
      formData: { model, newModel, version, brand },
      newModelRules,
      fields: { version: { items } },
      autoInfoAux,
    } = this

    if (items?.some(versionYear => versionYear.version.name === suggestedVersion)) {
      return false
    }

    const hasVersion = version?.hasOwnProperty('version') && version.version.hasOwnProperty('name') && !version?.id && !version?.version?.id
    if (!autoInfoAux && suggestedVersion?.length && hasVersion) {
      return true
    }

    const isSameModel = model?.id === autoInfoAux?.version?.version?.model?.id
    const isSameBrand = brand?.id === autoInfoAux?.version?.version?.model?.brand?.id

    if (!isSameModel || !isSameBrand) {
      return false
    }

    return suggestedVersion?.length && (model?.id || newModel?.length) && !newModelRules.length && hasVersion
  }

  get disabledVersion () {
    const { formData, disabledFields, message, isPpuValid, newModelRules } = this

    if (!formData.model?.id && !newModelRules.length && formData.newModel?.length) {
      return false
    }

    return !formData.model?.id || disabledFields || Boolean(message.length) || isPpuValid
  }
  }
