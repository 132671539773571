import { Entity } from '..'
import { Transform, Type } from 'class-transformer'
import { Person } from '../persons'
import dayjs, { Dayjs } from 'dayjs'
import { FileProcess } from '@/entities/files'

class UserMetadata {
  @Type(() => FileProcess)
  file: FileProcess

  get screenShotSmall () {
    const { file } = this

    return file?.file?.smallUrl
  }

  get screenShotLarge () {
    const { file } = this

    return file?.file?.largeUrl
  }
}

export class User extends Entity {
  @Type(() => Person)
  person: Person;

  @Type(() => Dayjs)
  @Transform(({ value }) => value && dayjs(value), { toClassOnly: true })
  lastSeen?: Dayjs;

  @Type(() => UserMetadata)
  metadata: UserMetadata

  email: string

  get lastSeenLocal () {
    return this.formatDateDayjs(this.lastSeen)
  }

  get userIsOnline () {
    const now = dayjs()
    if (!this.lastSeen) return false
    const lastSeen = dayjs(this.lastSeenLocal)
    const diffInMinutes = now.diff(lastSeen, 'minute')

    return diffInMinutes < 1
  }
}
