
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import dayjs from 'dayjs'
  import initials from 'initials'
  import { mapGetters } from 'vuex'

@Component({
  computed: {
    ...mapGetters('app', ['isMobile']),
  },
})
  export default class UserTable extends Vue {
  @Prop({ required: true }) users!: any[]
  isMobile!: boolean

  headers = [
    { text: 'En línea', value: 'userIsOnline', width: '90px' },
    { text: 'Usuario', value: 'person' },
    { text: 'Última conexión', value: 'lastSeen' },
    { text: 'Activo hace', value: 'active' },
    { text: 'Pantalla', value: 'metadata.screenShotSmall', width: '100px' },
  ]

  itemsPerPage = [5, 10, 15, 20, 25]
  cursor = {
    page: 1,
    size: 20,
  }

  isPhoto (user) {
    return Boolean(user?.person?.photo?.main)
  }

  getInitials (user) {
    const { person } = user
    const name = person?.firstName + ' ' + person?.surname
    const alias = person.alias || person.socialReason
    return initials(alias || name || (person.email as string)).toUpperCase()
  }

  formatLastSeen (lastSeen) {
    if (!lastSeen) return 'Desconocido'
    return dayjs(lastSeen).format('DD/MM/YYYY HH:mm')
  }

  formatActiveTime (lastSeen) {
    if (!lastSeen) return 'Desconocido'

    const now = dayjs()
    const lastSeenDate = dayjs(lastSeen)
    const diffInMinutes = now.diff(lastSeenDate, 'minute')
    const diffInHours = now.diff(lastSeenDate, 'hour')
    const diffInDays = now.diff(lastSeenDate, 'day')
    const diffInMonths = now.diff(lastSeenDate, 'month')
    const diffInYears = now.diff(lastSeenDate, 'year')

    if (diffInMinutes < 1) {
      return 'Activo hace unos segundos'
    } else if (diffInMinutes < 60) {
      return `Activo hace ${diffInMinutes} minuto${diffInMinutes > 1 ? 's' : ''}`
    } else if (diffInHours < 24) {
      return `Activo hace ${diffInHours} hora${diffInHours > 1 ? 's' : ''}`
    } else if (diffInDays < 30) {
      return `Activo hace ${diffInDays} día${diffInDays > 1 ? 's' : ''}`
    } else if (diffInMonths < 12) {
      return `Activo hace ${diffInMonths} mes${diffInMonths > 1 ? 'es' : ''}`
    } else {
      return `Activo hace ${diffInYears} año${diffInYears > 1 ? 's' : ''}`
    }
  }

  handlePageChange () {
    this.$emit('update:page', this.cursor.page)
    this.$emit('update:size', this.cursor.size)
  }

  getName (user) {
    const { person } = user
    const name = (person?.firstName || '') + ' ' + (person?.surname || '')

    return name.toUpperCase()
  }

  openLink (link) {
    if (!link?.length) return
    if (!link.startsWith('https://')) {
      link = 'https://' + link
    }

    window.open(link, '_blank')
  }
  }
