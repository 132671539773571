import { ResourceHeaders } from '..'
import {
  AlertConsignmentHeader,
  ChannelHeader,
  ConsignmentExpirationHeaders,
  ConsignmentHeaders,
  ContactHeader,
  IdHeader,
  MarketPricesHeader,
  PdfConsignmentHeader,
  PdfConsignmentWithdrawalHeader,
  PdfResponsabilityHeader,
  PersonHeader,
  PriceHeader,
  RetirementConsignmentCellHeader,
  StockButtonHeader,
  StockDailyHeader,
  StockLeadReceivedHeader,
  VehicleHeader,
  VehicleStatusHeader,
} from '../../headers'
import { Helpers } from '@/views/datatables/resources/helpers'
import { Lead } from '@/entities/crm'
import { Process } from '@/entities/settings'
import { PurchaseOrder } from '@/entities/purchase'
import { CellType } from '@/components/dataTables/cell'

export const headers: ResourceHeaders = [
  {
    ...IdHeader,
    text: 'Nº',
    value: '@',
    options: {
      expandOff: true,
      action: {
        edit: Helpers.linkedModel,
      },
    },
  },
  { ...VehicleHeader, text: 'Vehículo', value: 'negotiation.auto' },
  { ...VehicleStatusHeader, text: 'Estado de stock', value: 'stockStatus' },
  {
    ...AlertConsignmentHeader, text: 'Alerta', value: '@',
  },
  {
    ...RetirementConsignmentCellHeader, text: 'Retiro', value: '@',
  },
  {
    ...PersonHeader,
    text: 'Cliente',
    value: '@',
    options: {
      path: 'negotiation.client.name',
      displayPhone: true,
      trigger: ({
        negotiation: {
          inspection: { appraisal: { deal: { lead: { client } } } },
        },
      }: PurchaseOrder) => Helpers.linkedModel(client),
      action: {
        always: true,
        color: 'green',
        icon: 'mdi-whatsapp',
        messaging: true,
        trigger: (item: Lead, process: Process, system: string) => Helpers.contact(item, 'client', process, system),
      },
      disabledMissingProperty: ['negotiation.client.phone'],
    },
  },
  { ...ChannelHeader, text: 'Canal', value: 'negotiation.inspection.appraisal.deal.channel' },
  { ...ConsignmentExpirationHeaders, text: 'Vencimiento', value: '@' },
  { ...ConsignmentHeaders, text: 'Estado', value: '@' },
  { ...PriceHeader, text: 'Precio acordado', value: 'agreementPrice' },
  { ...PriceHeader, text: 'Utilidad proyectada', value: 'utility', width: 180 },
  {
    ...ContactHeader,
    text: 'Validador',
    sortable: false,
    value: '@',
    options: {
      disabledActionMissingProperty: {
        key: 'validator',
      },
      path: 'validator.name',
      action: {
        always: true,
        color: 'blue',
        icon: 'mdi-whatsapp',
        messaging: true,
        trigger: (item: PurchaseOrder, process: Process, system: string) => Helpers.contact(item, 'validator', process, system),
      },
      disabledMissingProperty: ['validator.phone'],
    },
  },
  { ...StockButtonHeader, text: 'Detalle Stock', value: '@', width: 140 },
  {
    ...StockLeadReceivedHeader,
    text: 'Leads recibidos',
    value: '@',
    width: 180,
  },
  {
    ...StockDailyHeader,
    text: 'Días',
    value: '@',
    width: 165,
  },
  // { ...TextHeader, text: 'Acreedor de prenda', value: '', width: 160 },
  {
    ...PdfResponsabilityHeader,
    text: 'Carta de responsabilidad',
    value: '@',
  },

  {
    ...PdfConsignmentHeader,
    text: 'Contrato de consignación',
    value: '@',
  },
  {
    ...PdfConsignmentWithdrawalHeader,
    text: 'Retiro consignación',
    value: '@',
  },
]

export default function (): ResourceHeaders {
  return headers
}
